import EzoList from "../../../../ui/components/List/EzoList";
import { useGetAppointmentTypesQuery } from "../../api/medispring";
import useURLQuery from "../../../../hooks/useURLQuery";
import { Icons } from "../../../../ui/assets";
import useUpdateSearchParams from "../../../../hooks/useUpdateSearchParams";
import SectionShimmer from "../../../../ui/components/Loading/SectionShimmer";
import Icon from "../../../../ui/components/Icon/Icon";

const TypeSelectionScreen = () => {
  
  const { hcp, place } = useURLQuery();
  const { data, isLoading } = useGetAppointmentTypesQuery(hcp);

  const updateParam = useUpdateSearchParams();

  return (
    <>
      {isLoading ? (
        <SectionShimmer />
      ) : (
        <EzoList
          data={data?.filter((type) => !place || type.placeId === place)}
          icon={(type) => <Icon asset={Icons.icons.calendar_fill} />}
          title={(type) => type.name!}
          details={(type) => `${type.duration?.toString()} minutes`}
          onItemSelection={(type) => {
            updateParam("type", type.calendarItemTypeId!);
          }}
        />
      )}
    </>
  );
};

export default TypeSelectionScreen;
