import {DataConnectorInterface} from "./DataConnectorInterface";
import {UserCredentials} from "../models/UserCredentials";
import {MedispringConnector} from "./medispring/connector/MedispringConnector";
import {AwellConnector} from "./awell/connector/AwellConnector";

const connectors = [
  MedispringConnector,
  AwellConnector
]

export enum ConnectionStatus {
  UNINITIALIZED = "uninitialized",
  INITIALIZED = "initialized",
  CONNECTED = "connected",
  ERROR = "error",
}


export class ConnectorProvider {
    private static _connectors: {[key:string]:DataConnectorInterface } = {};

    static isMethodValid<T, K extends keyof T>(
    instance: T,
    methodName: K
  ): instance is T & Record<K, (...args: any[]) => any> {
    return typeof instance[methodName] === "function";
  }

    static add(connector: DataConnectorInterface) {
        this._connectors[connector.id] =connector;
    }

    static async addConnectorWithOptions(ConnectorType:any,options:ConnectorOptions) {
      const connector = await ConnectorType.init(options);
      this.add(connector);
    
      return connector;
    }
  
  
    static getAll<U>() {
      return Object.values(this._connectors) as U[];
    }


    static async getAvailableProviders(credentials:UserCredentials):Promise<ConnectorOptions[]>{
      const promises = connectors.flatMap(async (connector) => {
        const connections = await connector.getAvailableConnections(credentials);
        return connections.map((connection) => {
          connection.connector = connector;
          connection.status = ConnectionStatus.UNINITIALIZED;
          return connection;
        });
      });

      const results = await Promise.all(promises);
      const flattenedConnections = results.flat().map((c) => ({...c,pki:credentials.pki}));
      return flattenedConnections;

    }
}


export interface ConnectorOptions {
  id?: string;
  connector?: Function;
  pki?: string;
  credentials?: UserCredentials;
  status?: ConnectionStatus;
  info?: any;
  recoveryKey?: string;
  generateNewKey?: boolean;
}

