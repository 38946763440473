import { ReactNode, createContext} from "react";

export interface ModalProps {
  id?: string;
  active?: boolean;
}

type ModalContextProps = {
  modal?: ReactNode;
  active: boolean;
  content: ReactNode;
  toggle: (component: ReactNode) => void;
};

export const ModalContext = createContext<ModalContextProps>({
  active: false,
  content: null,
  toggle: (component: ReactNode) => {},
});

