import React, {useContext, useEffect} from "react";

import styled, { keyframes, css } from "styled-components";

import { Size, Color, Breakpoint, Easing } from "../../tokens";

import useUpdateSearchParams from "../../../hooks/useUpdateSearchParams";
import { ModalContext } from "./ModalContext";
import { useModalSheet } from "./useModalSheet";
import { useSearchParams } from "react-router-dom";
import usePrevious from "../../../hooks/usePrevious";

interface SheetProps {
  active?: boolean;
}

//  ${(props) => props.active ? 'flex' : 'none'};

const translateIn = keyframes`
from {
  transform: translateY(100%);
  opacity:0;
}
to {
  transform: translateY(0);
  opacity:1;
}
`;

const translateOut = keyframes`
0% {
  transform: translateY(0);
  opacity:1;

}
99% {
  transform: translateY(100%);
  
  
}
100% {
  display: none;
}
`;

const translateOutDesktop = keyframes`
0% {
  transform: translateY(0);
  opacity:1;

}
99% {
  transform: translateY(100px);
  opacity:0;

}

100% {
  display:none;
}
`;

const translateDesktop = keyframes`
from {
  transform: translateY(100px);
  opacity:0;
}
to {
  transform: translateY(0);
  opacity:1;
}
`;

const ModalSheetContainer = styled.div<SheetProps>`
  box-sizing: border-box;
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  display: flex;
  z-index: 99999;

  @media (max-width: 400px) {
    position: fixed;
  }

  pointer-events: ${(props) => (props.active ? "auto" : "none")};
`;

const ModalSheetBackground = styled.div<{ visible?: boolean }>`
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  opacity: ${(props) => (props.visible ? 1 : 0)};
  backdrop-filter: blur(8px);

  transition: opacity ${Easing.IN_OUT};
  background-color: ${Color.MODAL_BACKGROUND};
`;

const Sheet = styled.div<{ visible?: boolean }>`
  opacity: 0;
  overflow: scroll;
  position: relative;
  box-sizing: border-box;
  width: 600px;
  height: 65%;
  margin: auto;
  background-color: #fff;
  border-radius: 8px;
  border: 1px solid ${Color.NEUTRAL_200};
  box-shadow: 0px 6px 80px rgba(0, 0, 0, 0.07),
    0px 2.50666px 33.4221px rgba(0, 0, 0, 0.0503198),
    0px 1.34018px 17.869px rgba(0, 0, 0, 0.0417275),
    0px 0.751293px 10.0172px rgba(0, 0, 0, 0.035),
    0px 0.399006px 5.32008px rgba(0, 0, 0, 0.0282725),
    0px 0.166035px 2.21381px rgba(0, 0, 0, 0.0196802);
  animation: ${translateDesktop} ${Easing.CUBIC};

  ${({ visible }) =>
    !visible &&
    css`
      animation: ${translateOutDesktop} ${Easing.CUBIC};
    `}

  @media (max-width: ${Breakpoint.MOBILE}px) {
    width: 100vw;
    border-radius: 16px 16px 0 0px;
    bottom: 0;
    margin: 0;
    padding-bottom: ${Size.XXL}px;
    position: absolute;
    animation: ${translateIn} ${Easing.CUBIC};

    ${({ visible }) =>
      !visible &&
      css`
        animation: ${translateOut} ${Easing.CUBIC};
      `}
  }
`;


const ModalSheet = () => {
  const setParam = useUpdateSearchParams();
  const setModal = useModalSheet();
  const [searchParams, setSearchParams] = useSearchParams();
  const prevAction = usePrevious(searchParams.get("action"));
  

  const { active, content } = useContext(ModalContext);

  useEffect(() => {
    
    if (content) {
     
       // @ts-ignore
      setParam("action", content?.key ?? "modal"); // key is not mandatory but could be useful for debugging
    } else {
      setSearchParams();
    }
  }, [content]);

  useEffect(() => {
    if (searchParams.get("action")) {
      setModal(content);
    } else if (prevAction) {
      setModal(null);
    }
  }, [searchParams]);


  return (
    <ModalSheetContainer active={content != null}>
      <ModalSheetBackground visible={content != null} onClick={() => setModal(null)} />
      <Sheet visible={active}>{content}</Sheet>
    </ModalSheetContainer>
  );
};

export default ModalSheet;
