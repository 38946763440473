import { createSlice } from "@reduxjs/toolkit";
import  { RootState } from "../../store";
import { PatientModel } from "../../models/PatientModel";
import { UserCredentials } from "../../models/UserCredentials";
import { ConnectionStatus, ConnectorOptions } from "../../connectors/ConnectorProvider";


interface AuthState {
  patients?: PatientModel[];
connections:{[id:string]:ConnectorOptions};
token?:AuthToken;
noConnectionAvailable?:boolean;
credentials?:UserCredentials;
isConnected?:boolean;

  recoveryKey?:string;
}

interface AuthToken {
  token:string;
  provider:TokenProvider;
}

export enum TokenProvider {
  BE_FAS = "be.fas"
}


export enum AppState {
  OUT = "OUT",
  AUTHENTICATED = "AUTHENTICATED",
  ACTIVE = "ACTIVE",
}


// REDUCER
const slice = createSlice({
  name: "auth",
  initialState: {
    isConnected: false,
    connections:{},
    patients:[],
    recoveryKey:undefined,

    
  } as AuthState,

  // TODO: add typing for action payloads
  reducers: {
    hasRecoveryKey: (state,action) => {
      state.recoveryKey = action.payload;
    },
   
    logout: (state) => {

      state.isConnected = false;
      state.patients = [];
      state.connections = {};
      state.credentials = undefined;
    },
    enterTheApp: (state) => {
      state.isConnected = true;
    },
    

    connectionComplete: (state,action) => {
       state.patients = (state.patients ?? []).concat(action.payload.patients);
    },
   

    
      connectionFailed: (state) => {
        // called upon login error
        state.noConnectionAvailable =true;  
      },
      connectionError: (state,action) => {
  
      state.connections[action.payload.id].status = ConnectionStatus.ERROR;
       
      },
     
      connectionInitialised: (state,action) => {
  
          console.log("Connection initialized",action.payload);
          state.connections[action.payload.id] = action.payload;


      },
      connectionSuccess: (state,action) => {

        console.log("Connection success",action.payload);

        state.connections[action.payload.id].status = ConnectionStatus.CONNECTED;
        state.connections[action.payload.id].pki = action.payload.info.pki;
        const patient = state.patients?.find(p => p.id === action.payload.info.id);
       
        if (patient){ 
          mergeIntoPatient(patient,action.payload.info);
          
        } else {
          state.patients = (state.patients ?? []).concat(action.payload.info);
        }

         
        },
      tokenAdded: (state,action) => {
          state.token = action.payload;
      },
  
      setCredentials: (state,action) => {
        if (!state.credentials){
          state.credentials = action.payload.credentials;
          if (action.payload.info){
            const patient = state.patients?.find(p => p.id === action.payload.info.id);
         
          if (patient){ 
            mergeIntoPatient(patient,action.payload.info);
            
          } else {
            state.patients = (state.patients ?? []).concat(action.payload.info);
          }
          }
        }
       
      }
 

  },
    
});


export const selectConnections = (state: RootState) => Object.values(state?.auth.connections);


export const selectAllPatients = (state: RootState) => state?.auth.patients;
export const selectCurrentCredentials = (state: RootState) =>
  state?.auth.credentials;


export const selectAppState = (state: RootState) => state.auth.isConnected ? 
                                                            AppState.ACTIVE : 
                                                            state.auth.credentials ?
                                                            AppState.AUTHENTICATED :
                                                            AppState.OUT;
                                                            
export const selectIsPatientLogged = (state: RootState) => state.auth.isConnected;




/*
TODO : review this
*/

const mergeIntoPatient = (patient:PatientModel,newPatient:PatientModel)=>{
  for (let key in newPatient){
    if (newPatient.hasOwnProperty(key)) {
      const patientKey = key as keyof PatientModel;
  
      if (newPatient[patientKey] !== undefined) {
        // Now using the setProperty function to bypass the restrictive typing
        setProperty(patient, patientKey, newPatient[patientKey]!);
      }
    }
  }
  return patient;
}


function setProperty<TObject, TKey extends keyof TObject>(
  obj: TObject,
  key: TKey,
  value: TObject[TKey]
): void {
  obj[key] = value;
}



// ACTIONS

export const { 
  enterTheApp,
  hasRecoveryKey,

  logout, 
  connectionComplete,
  connectionError, 
  connectionSuccess,
    connectionInitialised,
    tokenAdded,
    connectionFailed,
    setCredentials
} = slice.actions;

export default slice.reducer;
