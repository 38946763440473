import {useEffect, useState, useCallback} from "react";
import Button from "../../ui/components/Button";

import Typography from "../../ui/components/Typography/Typography";
import ButtonGroup from "../../ui/components/Layout/ButtonGroup";
import {useDispatch} from "react-redux";
import {hasRecoveryKey, TokenProvider} from "./AuthSlice";
import {useConnectMutation} from "../../api/authentication";
import Spacing from "../../ui/components/Layout/Spacing";
import {useTranslation} from "react-i18next";
import {getToken, launchIAMConnect} from "../../connectors/ehealth/api/iam";
import {useSearchParams} from "react-router-dom";
import Icon from "../../ui/components/Icon/Icon";
import {Icons} from "../../ui/assets";
import styled from "styled-components";

const Container = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: space-between;
  padding: 20px;
  height: 100%;
`;

const LoginScreen = () => {
  const dispatch = useDispatch();

  const [connect, { isLoading }] = useConnectMutation();
  const [isFetchingToken, setIsFetchingToken] = useState(false);

  const [params] = useSearchParams();
  const recoveryKey = params.get("key");

  // const [email, setEmail] = useState("jerome.cordiez+billcloud@gmail.com");
  // const [email, setEmail] = useState("jerome.cordiez+colombe@gmail.com");
  const [email] = useState("jerome.cordiez+ad@gmail.com");
  const [password] = useState("tiredaile");

  //const [email, setEmail] = useState("jerome.cordiez+ad@medispring.be");
 // const [password, setPassword] = useState("tiredaile");

  useEffect(() => {
    if (recoveryKey) {
      dispatch(hasRecoveryKey(recoveryKey));
    }
  }, [recoveryKey, dispatch]);


  const retrieveTokens = useCallback(async (code: string) => {
    console.log("retrieving tokens");
    setIsFetchingToken(true);
    const authToken = {...await getToken(code), tokenProvider:TokenProvider.BE_FAS};
    setIsFetchingToken(false);
    if (authToken.token) {
      connect(authToken);
    }
  }, [connect, setIsFetchingToken]);

  const code = params.get("code");
  useEffect(() => {
    if (code) retrieveTokens(code); 
  }, [code, retrieveTokens]);
  

  const { t } = useTranslation();

  return (
    <Container>
       
<div>
<Icon illustration asset={Icons.illustrations.launch} />
<Spacing xlarge />

      <Typography.body align="center">Bienvenue sur E·ZO, l'application santé qui vous connecte à votre équipe soignante et à votre dossier de santé</Typography.body>
     
        <ButtonGroup isLoading={isLoading || isFetchingToken}>
          <Button
            primary
            title={t("login.connect") as string}
            onPress={launchIAMConnect}
          />

          <Button title="Demo login" onPress={() => connect({ userName: email, password, })} />
        </ButtonGroup>
        </div>

      <Typography.small align="center">
        By pressing the button above, you agree to our <a href="/terms">Terms of Service</a> and <a href="/privacy">Privacy Policy</a>.
      </Typography.small>
    </Container>
  );
};

export default LoginScreen;
