import Typography from "../../ui/components/Typography/Typography";

import {useSelector} from "react-redux";
import {selectAllPatients} from "../login/AuthSlice";
import Avatar from "../../ui/components/Avatar/Avatar";

import useResources from "../../hooks/useResources";
import {formatDateAsMonthAndYear, formatFirstName, getUpdateTime,} from "../../utils/formatting";
import {useNavigate} from "react-router-dom";
import {AgendaItemModel, AgendaItemStatusEnum, antiChronologically, isNotPast,} from "../../models/AgendaItemModel";
import {mostRecent,} from "../../models/DocumentReference";
import Assets, {Icons} from "../../ui/assets";
import Button from "../../ui/components/Button";
import {ResourceColors, Tints} from "../../ui/tokens";
import {Fragments} from "./HomeSection";
import {MedicationModel} from "../../models/MedicationModel";
import {useTranslation} from "react-i18next";
import {HCPModel} from "../../models/HCPModel";
import styled from "styled-components";
import usePullToRefresh from "../../hooks/usePullToRefresh";
import {DocumentResource} from "../../models/DocumentResource";
import MainNavigation, {MenuItem} from "../../ui/components/MainNavigation";
import ListSection from "../../ui/components/Layout/ListSection";
import {AgendaItemRenderer} from "../agenda/AgendaItemRenderer";
import {DocumentRenderer} from "../documents/DocumentRenderer";
import Spacing from "../../ui/components/Layout/Spacing";
import {ResourceType} from "../../types/ResourceType";
import useIsCurrentRoute from "../../hooks/useIsCurrentRoute";
import Badge from "../../ui/components/Badge";
import dayjs from "dayjs";
import {API_TIME_FORMAT} from "../../../services/constants";
import {useModalSheet} from "../../ui/components/ModalSheet/useModalSheet";
import CreateAppointmentFlow from "../../connectors/medispring/features/agenda/CreateAppointmentFlow";
import {useFlowContext} from "../../ui/components/ScreenFlow/FlowContext";
import AvatarGroup from "../../ui/components/Avatar/AvatarGroup";
import useOptions from "../../hooks/useOptions";
import {FlowScreenProps} from "../../ui/components/ScreenFlow/ScreenFlow";
import {useMarkResourceAsViewedMutation} from "../../api/healthdata";
import {getViewedOnTstampByResource} from "../../connectors/medispring/mappers";

const ScreenContainer = styled.div``;

const HomeScreen = ({name}:FlowScreenProps) => {
  const patients = useSelector(selectAllPatients);
  const isScreenVisible = useIsCurrentRoute("");


  const options = useOptions();

  const DISPLAY_MAX = 3;

  const {setActions} = useFlowContext();

  setActions(
    "home",
    <AvatarGroup
            persons={patients}
            large
            onPress={() => options.show()}
          />);

  const navigate = useNavigate();

  const {
    data: appointments,
    isLoading,
    refetch,
  } = useResources<AgendaItemModel>(ResourceType.APPOINTMENTS, isScreenVisible);

  const {
    data: documents,
    isLoading: isLoadingDocs,
    refetch: refetchDocs,
  } = useResources<DocumentResource>(ResourceType.DOCUMENTS, isScreenVisible);

  const {
    data: medications,
    refetch: refetchMeds,
  } = useResources<MedicationModel>(ResourceType.MEDICATIONS, isScreenVisible);
 
  const { data: providers } =
    useResources<HCPModel>(ResourceType.PROVIDERS, isScreenVisible);

  const visibleDocuments = documents?.slice().sort(mostRecent);

  const { t } = useTranslation();

  function getLatestDoc(docs: DocumentResource[] | undefined) {
    return docs?.slice().sort(mostRecent)[0];
  }
  function getLatestMed(meds: MedicationModel[] | undefined) {
    return meds?.slice().sort((a, b) => (a.updated! < b.updated! ? 1 : -1))[0];
  }

  function getCareTeamStatus(team: HCPModel[]) {
    return team?.length ? `${team.length} soignant(s)` : "Pas de soignants";
  }

  function getAgendaStatus(events: AgendaItemModel[]) {
    const proposed = events?.filter(
      (e) => e.status === AgendaItemStatusEnum.PROPOSED
    );
    const list = [
      scheduled?.length ? `${scheduled.length} rdv prévu(s)` : undefined,
      proposed?.length ? `${proposed.length} à faire` : undefined,
    ];
    return list.filter((l) => l).join(", ");
  }

  const handleRefresh = () => {
    refetchMeds();
    refetchDocs();
    refetch();
    //refetchVaccinations()
  };

  const setModal = useModalSheet();

  usePullToRefresh(handleRefresh);

  const scheduled = appointments
    ?.filter(
      (a) =>
        a.status === AgendaItemStatusEnum.BOOKED ||
        a.status === AgendaItemStatusEnum.PENDING
    )
    .filter(isNotPast)
    .sort(antiChronologically);

  const proposed = appointments
    ?.filter((a) => a.status === AgendaItemStatusEnum.PROPOSED)
    .sort(antiChronologically);

  const items: MenuItem[] = [
    {
      title: t("menu.documents"),
      subtitle: `Màj ${getUpdateTime(getLatestDoc(documents)?.issued)}`,
      color: ResourceColors[ResourceType.DOCUMENTS],
      icon: Icons.specialties.document,
      link: Fragments.DOCUMENTS,
      count: documents?.filter((d) => !getViewedOnTstampByResource(d)).length,
    },
    {
      title: t("menu.medication"),
      subtitle: `Màj ${getUpdateTime(getLatestMed(medications)?.updated)}`,
      color: ResourceColors[ResourceType.MEDICATIONS],
      icon: Icons.medication.pill_xs,
      link: Fragments.MEDICATION,
    },
    {
      title: t("menu.agenda"),
      subtitle: getAgendaStatus(appointments),
      color: ResourceColors[ResourceType.APPOINTMENTS],
      icon: Icons.icons.calendar_fill,
      link: Fragments.AGENDA,
      count: proposed?.filter((d) => !getViewedOnTstampByResource(d)).length,
    },
    {
      title: t("menu.team"),
      subtitle: getCareTeamStatus(providers),
      color: ResourceColors[ResourceType.PROVIDERS],
      icon: Icons.specialties.document,
      link: Fragments.PROVIDERS,
    },
  ];
  const getExtra = (total: number) =>
    total > 0 ? `+ ${total} ${t("home.more")}` : "";

  const [markResourceAsViewed] = useMarkResourceAsViewedMutation();

  return (
    <ScreenContainer>
      <Typography.h1>
        {t("home.hello") ?? ""} {patients ? formatFirstName(patients[0]) : ""}
      </Typography.h1>

      <MainNavigation items={items} />

      <Spacing large />

      <ListSection
        title={t("home.appointments") as string}
        accessory={
          <Button
            small
            title={t("home.add") as string}
            onPress={() => setModal(<CreateAppointmentFlow />)}
          ></Button>
        }
        isLoading={isLoading}
        info={getExtra(scheduled?.length - DISPLAY_MAX)}
        empty={Assets.empty.agenda}
        data={scheduled?.slice(0, DISPLAY_MAX)}
        length={DISPLAY_MAX}
        renderer={AgendaItemRenderer}
        onItemSelection={(item) => {
          markResourceAsViewed({resourceId: item.id, resourceType: ResourceType.APPOINTMENTS});
          navigate(`${Fragments.AGENDA}/${item.id}`)
        }}
      />

      {proposed?.length > 0 && (
        <ListSection
          title={t("home.todo") as string}
          isLoading={isLoading}
          empty={Assets.empty.agenda}
          data={proposed?.slice()}
          length={DISPLAY_MAX}
          renderer={{
            ...AgendaItemRenderer,
            title: (item) => item.title ?? "",
            subtitle: (item) => {
              const d = dayjs(item.start.toString(), API_TIME_FORMAT);
              return d.isBefore(dayjs().add(1, "day"))
                ? formatDateAsMonthAndYear(item.start)
                : getUpdateTime(item.start);
            },
            iconRight: (item) => {
              const d = dayjs(item.start.toString(), API_TIME_FORMAT);
               if (d.isBefore(dayjs().add(1, "day"))) return <Avatar
               small
               icon={<img src={Assets.icons!.warning} alt="warning"/>}
               tint={Tints.YELLOW_LIGHT}
             />
             return item.viewedOn ? undefined : <Badge />
            }
          }}
          onItemSelection={(item) => {
            markResourceAsViewed({resourceId: item.id, resourceType: ResourceType.APPOINTMENTS});
              navigate(`${Fragments.AGENDA}/${item.id}`)
        }}
        />
      )}
      <ListSection
        title={t("home.last_docs") as string}
        isLoading={isLoadingDocs}
        empty={Assets.empty.agenda}
        data={visibleDocuments?.slice(0, 5)}
        length={DISPLAY_MAX}
        renderer={{
          ...DocumentRenderer,
          iconRight: (item) =>
              item.viewedOn ? undefined : <Badge />,
        }}
        onItemSelection={(item) => {
          markResourceAsViewed({resourceId: item.id, resourceType: ResourceType.DOCUMENTS});
          navigate(`${Fragments.DOCUMENTS}/${item.id}`)

        }
        }
      />
    </ScreenContainer>
  );
};

export default HomeScreen;
