import React, { ReactElement, ReactNode, useEffect, useRef, useState } from "react";
import styled from "styled-components";
import { Breakpoint, Color, Elevation, Size } from "../../tokens";
import Typography from "../Typography/Typography";
import HomeButton from "../HomeButton";
import FilterButton from "../FilterButton";

const NavBarContainer = styled.div`
  display: flex;
  justify-content: space-between;
  box-sizing: content-box;
  flex-wrap: wrap;
  position: absolute;

  align-items: center;
  width: calc(100% - ${Size.L}px);
  margin-bottom: ${Size.L}px;
  order: 15;
  min-height: ${Size.XXL}px;
  top: 0;
  z-index: 99999;
  gap: ${Size.S}px;
  padding: ${0}px ${Size.M}px;

  @media (max-width: ${Breakpoint.MOBILE}px) {
    box-sizing: content-box;

    width: calc(100% - ${Size.L}px);
  }
`;

const NavBarBackground = styled.div<{ show: boolean }>`
  position: absolute;
  left: 0;
  top: -${Size.M}px;
  background-color: ${(props) =>
    !props.show ? Color.NEUTRAL_100 : Color.WHITE};
  backdrop-filter: blur(10px);

  box-shadow: ${(props) => (!props.show ? Elevation.L : "none")};
  border-bottom: ${(props) =>
    !props.show ? `1px solid ${Color.NEUTRAL_300}` : "none"};
  width: 100%;
  height: calc(100% + ${Size.M}px);
  z-index: -1;
`;

const LeftContainer = styled.div`
  display: flex;
  justify-content: flex-start;
  gap: ${Size.S}px;
  min-width: ${Size.L}px;
`;

const RightContainer = styled.div`
  display: flex;
  gap: ${Size.S}px;
 
  min-width: ${Size.L}px;
  justify-content: flex-end;
`;

const TitleContainer = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  z-index: 100;
`;

interface Props {
  left?: ReactNode ;
  right?: ReactNode ;
  title?: string;
  showTitle?: boolean;
  active?: number;

  home?: boolean;
  options?: boolean;

  showSubtitle?: boolean;
  search?: boolean;

  children?: ReactElement | Array<ReactElement | undefined | boolean>;
}

function isIntersecting(element1: HTMLElement, element2: HTMLElement) {
  if (!element1 || !element2) return false;
  const rect1 = element1.getBoundingClientRect();
  const rect2 = element2.getBoundingClientRect();

  return (
    rect1.left < rect2.right &&
    rect1.right > rect2.left &&
    rect1.top < rect2.bottom &&
    rect1.bottom > rect2.top + Size.M
  );
}

const BAR_HEIGHT = Size.XXL;
const NavBar = ({
  left,
  right,
  title,
  home,
  active,
  options,
  showTitle,
  showSubtitle,
  search,
}: Props) => {
  const selfRef = useRef<null | HTMLDivElement>(null);

  const [subtitle, setSubtitle] = useState<string | null | undefined>();

  const [scrollOpacity, setScrollOpacity] = useState(0);

  useEffect(() => {
    const parentView = selfRef.current?.parentElement?.querySelector(".active");

    if (!parentView) return;

    const handleScroll = () => {
      const { scrollTop } = parentView;

      const scrollPercentage = (scrollTop - BAR_HEIGHT) / BAR_HEIGHT; // (scrollHeight - clientHeight);
      const calculatedOpacity = scrollPercentage;

      if (showSubtitle) {
        setSubtitle(null);

        (
          Array.from(
            parentView.querySelectorAll(".active .section")
          ) as HTMLElement[]
        ).forEach((section) => {
          if (isIntersecting(selfRef.current!, section)) {
            setSubtitle(`${section.querySelector(".h4")?.textContent}`);
          }
        });
      }

      /*(Array.from(parentView.querySelectorAll(".h1")) as HTMLElement[]).forEach(
        (h1) =>
          h1.style.setProperty("opacity", (1 - calculatedOpacity).toString())
      );*/

      setScrollOpacity(showTitle ? 1 : calculatedOpacity);
    };

    parentView.addEventListener("scroll", handleScroll);

    handleScroll();

    return () => {
      parentView.removeEventListener("scroll", handleScroll);
    };
  }, [active, showTitle, showSubtitle]);

  return (
    <NavBarContainer ref={selfRef}>
      <NavBarBackground show={!!showTitle} style={{ opacity: scrollOpacity }} />
      <LeftContainer>{home ? <HomeButton /> : left}</LeftContainer>

      <TitleContainer style={{ opacity: !!showTitle ? 1 : scrollOpacity }}>
        <Typography.h3>{title ?? ""}</Typography.h3>
        <Typography.small>{subtitle ?? ""}</Typography.small>
      </TitleContainer>

      <RightContainer>{options ? <FilterButton /> : right}</RightContainer>
    </NavBarContainer>
  );
};

export default NavBar;
