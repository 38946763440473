import styled from "styled-components";
import Typography from "./Typography/Typography";
import { Size, Color, Tints } from "../tokens";
import { PrescriptionModel } from "../../models/PrescriptionModel";
import Barcode from "react-barcode";
import { DocumentContent } from "../../models/DocumentReference";
import Avatar from "./Avatar/Avatar";
import { Icons } from "../assets";
import Icon from "./Icon/Icon";
import { useEffect, useState } from "react";
import { Spinner } from "./Loading/Spinner";

const Container = styled.a`
  display: flex;
  align-items: center;
  gap: ${Size.S}px;

  padding: ${Size.S}px 0px;
`;

interface Props {
  prescription?: PrescriptionModel;
  document?: DocumentContent;
  isLoading?: boolean;
}
const Attachment = ({ prescription, document, isLoading }: Props) => {
  const [url, setUrl] = useState<string>("");

  useEffect(() => {
    setUrl(
      URL.createObjectURL(
        new Blob([document?.attachment as ArrayBuffer], {
          type: document?.format,
        })
      )
    );
  }, [document]);

  return (
    <Container href={url} target="_blank">
      <Avatar
        square
        small
        icon={
         
        <Icon asset={Icons.icons.prescription_fill} color="#fff" />
          
        }
        tint={Tints.DARK}
      />
      {prescription?.rId && <Barcode value={prescription?.rId} />}
      <div style={{ flex: 1 }}>
        <Typography.small color={Color.NEUTRAL_600}>
          {isLoading ? "Loading..." : document?.name}
        </Typography.small>
      </div>
    </Container>
  );
};

export default Attachment;
