
import {
  Apis,
  Device,
  HealthcareParty,
  hex2ua,
  Patient,
  pkcs8ToJwk,
  ShaVersion,
  spkiToJwk,
  User,
} from "@icure/api";

import { KEY_STORAGE_PREFIX } from "../api/constants";

export async function initCryptoForUserAndPatientId(
  api: Apis,
  user: User,
  id: string
) {
  // console.log(await AsyncStorage.getAllKeys())

  const patientRaw = await api.patientApi.getPatientRaw(id);
  if (!patientRaw.publicKey) {
    await addFirstKeyPairToPatient(api, user, patientRaw);
    return true;
  }

  const keyPairs = await loadKeyPairsFromDevice(api, patientRaw);
  const hasExistingKeys = keyPairs !== undefined && keyPairs.length > 0;

  if (!hasExistingKeys) {
    return false;
  } else {
    return true;
  }
}

// Returns all currently public keys available to patient
export function getAllPublicKeys(
  owner: Patient | HealthcareParty | Device
): string[] {
  return [
    ...new Set(
      Object.keys(owner.aesExchangeKeys ?? {}).concat(owner.publicKey ?? "")
    ),
  ].filter((k) => k !== "");
}

// loads keypairs available on device
export async function loadKeyPairsFromDevice(
  api: Apis,
  patient: Patient
): Promise<
  | Array<{
      publicKey: globalThis.JsonWebKey;
      privateKey: globalThis.JsonWebKey;
    }>
  | undefined
> {
  const publicKeys = getAllPublicKeys(patient);

  if (publicKeys.length <= 0) {
    return undefined;
  }
  const keypairs: Array<{
    publicKey: globalThis.JsonWebKey;
    privateKey: globalThis.JsonWebKey;
  }> = [];
  for (const publicKey of publicKeys) {
    const location = getKeyPairLocationInStorage(patient.id!, publicKey);
    const keypair = await api.cryptoApi.keyStorage.getKeypair(location);

    if (keypair !== undefined) {
      keypairs.push(keypair);
     // await api.cryptoApi.cacheKeyPair(keypair);
    }
  }
  return keypairs;
}

export async function storeAndCacheKeyPair(
  api: Apis,
  patient: Patient,
  publicKeyHex: string,
  privateKeyHex: string
) {
  const keysLocationInStorage = getKeyPairLocationInStorage(
    patient.id!,
    publicKeyHex
  );
  const keyPairInJwk = {
    publicKey: spkiToJwk(hex2ua(publicKeyHex), ShaVersion.Sha1),
    privateKey: pkcs8ToJwk(hex2ua(privateKeyHex)),
  };
  await api.cryptoApi.keyStorage.storeKeyPair(
    keysLocationInStorage,
    keyPairInJwk
  );
 //await api.cryptoApi.cacheKeyPair(keyPairInJwk);
}

//
async function addFirstKeyPairToPatient(
  api: Apis,
  user: User,
  patient: Patient
) {
  /*
  const { publicKeyHex } = await createStoreAndCacheKeyPair(api, patient);

  const p = {
    ...patient,
    publicKey: publicKeyHex,
    tags: (patient.tags ?? []).concat(
      normalizeCode({
        type: "PATIENT-APP",
        code: "has-key-pair",
        version: "1",
      })
    ),
  };
  
  const u = await api.userApi.getCurrentUser();
  await api.patientApi.modifyPatientRaw(
    {
      ...(await api.patientApi.initDelegationsAndEncryptionKeys(p, u, undefined)),
  
    }
  )

  await sendFirstKeyPairNotification(api, user);
  */
}

export async function addKeyPairToPatient(
  api: Apis,
  user: User,
  patient: Patient
) {
  /*
   api.cryptoApi.emptyHcpCache(patient.id!)
  const { publicKey, privateKey } = await api.cryptoApi.addNewKeyPairForOwnerId(
    api.maintenanceTaskApi,
    user,
    patient.id!
  );
 
  await storeAndCacheKeyPair(api, patient, publicKey, privateKey);

  return privateKey
  */
}



export function getKeyPairLocationInStorage(id: string, key: string): string {
  return `${KEY_STORAGE_PREFIX}.${id}.${key.slice(-32)}`;
}
