/* eslint import/no-unused-modules:0 */

import dayjs from "dayjs";
import duration from "dayjs/plugin/duration";
import relativeTime from "dayjs/plugin/relativeTime";
import { API_TIME_FORMAT } from "../connectors/medispring/api/constants";
import { PersonModel } from "../models/PersonModel";
import { AddressModel } from "../models/AddressModel";
import { PatientModel } from "../models/PatientModel";
import { HCPModel } from "../models/HCPModel";
import { DocumentResource, DocumentType } from "../models/DocumentResource";
import { DiagnosticReportModel } from "../models/DiagnosticReportModel";
import { t } from "i18next";
// eslint-disable-next-line import/no-named-as-default-member
dayjs.extend(duration);
// eslint-disable-next-line import/no-named-as-default-member
dayjs.extend(relativeTime);

/**
 * Gets outputs corresponding to inputs sequentially (one async method at a time) and returns list of outputs
 * @param inputs array of input values
 * @param inputToPromiseGenerator function that transforms input into Promise of output
 * @returns list of outputs
 */
export async function awaitSequentially<T, U>(
  inputs: T[],
  inputToPromiseGenerator: (input: T) => Promise<U | U[]>
): Promise<U[]> {
  return await inputs.reduce(
    async (p, newIds) =>
      await p.then(async (docs) =>
        docs.concat(await inputToPromiseGenerator(newIds))
      ),
    Promise.resolve([] as U[])
  );
}

export async function delay(ms: number): Promise<void> {
  return await new Promise((resolve) => setTimeout(resolve, ms));
}

export function getImageURI(b: ArrayBuffer) {
  const charArr = new Uint8Array(b).reduce(
    (data, byte) => data + String.fromCharCode(byte),
    ""
  );
  const img = btoa(charArr);
  return "data:image/jpg;base64," + img;
}

export function getPatientInitials(profile: PersonModel) {
  return (
    (profile.firstName || "").charAt(0) + (profile.lastName || "").charAt(0)
  ).toUpperCase();
}

export async function asyncMap<U>(
  fn: (...args: [U]) => Promise<any>,
  array: U[]
) {
  return await Promise.all(array.map(fn));
}

// FORMATTING FUNCTIONS
// one day we should consider cleaning this up

export function capitalizeFirstLetter(string: string) {
  return string.charAt(0).toUpperCase() + string.slice(1);
}

export function shortAdministrationMode(mode?: string) {
  if (!mode) return "unité(s)";
  return mode?.toLowerCase().includes("comp")
    ? "comprimé(s)"
    : mode?.toLowerCase().includes("pomm")
    ? "application"
    : mode;
}

export function formatName(person?: PatientModel | HCPModel) {
  return person
    ? `${capitalizeFirstLetter(person.firstName ?? "")} ${
        person.lastName ?? ""
      }`
    : "";
}

export function getLocalizedSpecialty(specialty: string) {
  return t(`specialties.${specialty}`);
}

export function getDocumentTitle(doc: DocumentResource) {
  if (doc.type === DocumentType.REPORT) {
    if (!doc.specialty) return formatDate(doc.issued);
    return `${t("documents.report")}: ${
      t(`specialties.${doc.specialty}`) ?? ""
    } ${
      (doc as DiagnosticReportModel).procedure
        ? "- " + (doc as DiagnosticReportModel).procedure
        : ""
    }`;
    // return str;
  } else if (doc.type === DocumentType.LAB_RESULT) 
  {
    return `${t("documents.result")}`
  }
  return doc?.title ?? "Document";
}

export function getDocumentSubtitle(doc: DocumentResource) {
  if (doc.type === DocumentType.REPORT) {
    return formatName((doc as DiagnosticReportModel).performer); //.substring(0, 30);
  }

  return formatName((doc as DiagnosticReportModel).hcp as HCPModel);
}

export function getDocumentDetails(doc: DocumentResource) {
  //if (doc.type === DocumentType.REPORT){
  return `${formatDate((doc as DiagnosticReportModel).issued)}`;
  //}

  //return '';
}

export function getAddressAsURIComponent(address: AddressModel) {
  return encodeURIComponent(
    `${address.streetName ?? ""} ${address.houseNumber ?? ""}, ${
      address.zip ?? ""
    } ${address.city ?? ""}`
  );
}

export function initialsAndLastNamme(person?: PatientModel | HCPModel) {
  return person
    ? `${(person.firstName ?? "J").charAt(0).toUpperCase()}. ${
        person.lastName ?? ""
      }`
    : "";
}
export function formatFirstName(person?: PatientModel | HCPModel) {
  return person
    ? `${capitalizeFirstLetter(person.firstName?.split(" ")[0] ?? "")}`
    : "";
}

export function formatTime(slot: number) {
  return dayjs(String(slot), API_TIME_FORMAT).format("HH:mm");
}

export function formatDate(date?: number, dateOnly?: boolean) {
  return date
    ? dayjs(
        date.toString(),
        API_TIME_FORMAT.substring(0, date.toString().length)
      ).format(dateOnly ? "DD/MM/YY" : "DD MMM YYYY")
    : "...";
}

export function formatDateAsMonthAndYear(date?: number) {
  return date
    ? dayjs(
        date.toString(),
        API_TIME_FORMAT.substring(0, date.toString().length)
      ).format("MMM YYYY")
    : "...";
}

export function formatDateAndTime(date?: number, short?: boolean) {
  return date
    ? dayjs(date.toString(), API_TIME_FORMAT).format(
        short ? "D MMM, HH:mm" : "D MMM, HH:mm"
      )
    : "";
}

export function getUpdateTime(date?: number) {
  return date
    ? `${capitalizeFirstLetter(
        dayjs(date.toString(), API_TIME_FORMAT).fromNow()
      )}`
    : "Pas de documents";
}
export function getDate(date: number) {
  return dayjs(date.toString(), API_TIME_FORMAT).format("DD");
}
export function getMonth(date: number) {
  return dayjs(date.toString(), API_TIME_FORMAT).format("MMM");
}
export function timeTo(date: number) {
  return dayjs(date.toString(), API_TIME_FORMAT).fromNow();
}
export function formatAddress(address: AddressModel) {
  return address
    ? `${address.houseNumber ?? ""} ${address.streetName ?? ""}, ${
        address.zip ?? ""
      } ${address.city ?? ""}`
    : `Unknown address`;
}
