import styled from "styled-components";
import Avatar from "./Avatar/Avatar";
import { PersonModel } from "../../models/PersonModel";
import Typography from "./Typography/Typography";
import { formatName, } from "../../utils/formatting";
import { Breakpoint, Size } from "../tokens";

const Container = styled.div`
  display: flex;
  flex-direction: row;
  gap: ${Size.S}px;
  margin-bottom: ${Size.M}px;
  width: 100%;
  padding: ${Size.M}px ${Size.XS}px;
  align-items: center;

  @media (max-width: ${Breakpoint.MOBILE}px) {
    display: flex;
  }
`;

const Name = styled.div`
  flex-direction: column;
  @media (min-width: ${Breakpoint.DESKTOP}px) {
    display: flex;
  }
`;

interface Props {
  person?: PersonModel;
  details?: string;
}
const UserProfile = ({ person, details }: Props) => {
  return (
    <>
      <Container>
        <Avatar person={person} small />
        <Name>
          <Typography.body>{formatName(person)}</Typography.body>
          <Typography.small>{details ?? ""}</Typography.small>
        </Name>
      </Container>
    </>
  );
};

export default UserProfile;
