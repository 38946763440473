import { Color, Size } from "../tokens";

interface Props {
  label?: string;
  property?: string;
  selected?: boolean;
  onChange?: () => void;
}

const RadioButton = ({
  label,
  property,
  selected,
  onChange = () => {},
}: Props) => {
  return (
    <div
      style={{
        display: "flex",
        gap: 8,
        justifyItems: "center",
      }}
    >
      <input
        style={{ flexGrow: 0, width: 16, accentColor: Color.NEUTRAL_800 }}
        type="radio"
        autoComplete={property}
        checked={selected}
        onChange={onChange}
      />
      <label style={{ flex: 1 }}>{label}</label>
    </div>
  );
};

export default RadioButton;
