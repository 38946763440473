import Assets, { Icons } from "../assets";
import { Breakpoint } from "../tokens";
import Button from "./Button";
import Icon from "./Icon/Icon";
import MobileButton from "./MobileButton";

const removeOptions = (event: MouseEvent) => {
  const options = document.querySelector(".options");

  if (event.target && options?.contains(event.target as Node)) {
    return;
  }
  event.stopPropagation();
  document.removeEventListener("click", removeOptions, { capture: true });
  options?.classList.remove("active");
};

const showOptions = () => {
  const options = document.querySelector(".options");
  options?.classList.add("active");
  setTimeout(() => {
    document.addEventListener("click", removeOptions, { capture: true });
  }, 10);
};

const FilterButton = () => {
  return (
    <MobileButton onPress={showOptions} breakpoint={Breakpoint.DESKTOP}>
      <Button secondary icon={<Icon asset={Icons.navigation!.filter} />} />
    </MobileButton>
  );
};
export default FilterButton;
