import Avatar from "../../ui/components/Avatar/Avatar";
import {
  formatDate,
  getDocumentTitle,
} from "../../utils/formatting";
import { Icons } from "../../ui/assets";
import { Color, Tints } from "../../ui/tokens";
import Icon from "../../ui/components/Icon/Icon";
import { DocumentResource, DocumentType } from "../../models/DocumentResource";

export const DocumentRenderer = {
  title: getDocumentTitle,
  // subtitle:getDocumentSubtitle,
  details: (item: DocumentResource) => formatDate(item.issued, true),
  //subtitle:(item:DocumentReference) => `Créée le ${formatDateAndTime(item.created!)} par ${formatName(item.author)}`,
  /*iconRight: (item: DocumentReference) => {
    let p;
    if (item.context){
      p = [item.author!,item.context.related?.resultsInterpreter!, item.context.related?.subject!]
    } else {
      p = [item.author!];
    }
    return  <AvatarGroup persons={p} />}
  ,*/
  icon: (item: DocumentResource) => {
    
    return (
      <Avatar
        square
        icon={
          <Icon
            asset={
              item.type === DocumentType.MEDICATION_PRESCRIPTION
                ? Icons.icons.prescription_fill
                : item.type === DocumentType.LAB_RESULT
                ? Icons.specialties.blood
                : item.type === DocumentType.MESSAGE
                ? Icons.icons.message
                : getIconFromSpecialty(item.specialty)
            }
            color={Color.WHITE}
          />
        }
        tint={
          item.type === DocumentType.MEDICATION_PRESCRIPTION
            ? Tints.PRESCRIPTION
            : item.type === DocumentType.LAB_RESULT
            ? Tints.LAB_RESULT
            : item.type === DocumentType.MESSAGE
            ? Tints.MESSAGE
            : Tints.REPORT
        }
      />
    );
  },
  /*
  iconRight: (item: DocumentReference) => {
    return <Typography.small>{formatDate(item.created, true)}</Typography.small>
  }*/
};


const getIconFromSpecialty = (specialty?: string) => {
  switch (specialty) {
    case "CARDIOLOGY":
      return Icons.specialties.heart;
    case "ORTHOPEDICS":
      return Icons.specialties.bones;
    case "GASTROENTEROLOGY":
      return Icons.specialties.stomach;
    case "CLINICAL_BIOLOGY":
      return Icons.specialties.lab;
    case "ANATOMOPATHOLOGY":
      return Icons.specialties.lab;
    case "PHYSIOTHERAPY":
      return Icons.specialties.physio;
    default:
      return Icons.specialties.document;
  }
};
