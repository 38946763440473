// eHealth constants
const BASE_URL_ACC = "https://api-acpt.ehealth.fgov.be";
//const BASE_URL_PROD = "https://api.ehealth.fgov.be";
const REALM_ID = "healthcare";

export const CLIENT_ID = "medispring-patientapp";

const BASE_URL = `${BASE_URL_ACC}`;
const ENDPOINT = `${BASE_URL}/auth/realms/${REALM_ID}/protocol/openid-connect`;

const ROOT_URL = window.location.origin;

export const REDIRECT_ROUTE = "/app/auth"

export const REDIRECT_URI = `${ROOT_URL}${REDIRECT_ROUTE}`;
export const AUTH_ENDPOINT = `${ENDPOINT}/auth`;
export const TOKEN_ENDPOINT = `${ENDPOINT}/token`;

export const INFO_ENDPOINT = `${ENDPOINT}/userinfo`;
