
import Typography from "../../ui/components/Typography/Typography";

import useResources from "../../hooks/useResources";
import { ResourceType } from "../../types/ResourceType";
import EzoList from "../../ui/components/List/EzoList";
import Avatar from "../../ui/components/Avatar/Avatar";
import {  Tints } from "../../ui/tokens";
import Assets, { Icons } from "../../ui/assets";
import { VaccinationModel } from "../../models/VaccinationModel";
import {  formatDateAsMonthAndYear } from "../../utils/formatting";
import SectionShimmer from "../../ui/components/Loading/SectionShimmer";
import Icon from "../../ui/components/Icon/Icon";
import Spacing from "../../ui/components/Layout/Spacing";
import Section from "../../ui/components/Layout/Section";
import { useEffect, useState } from "react";
import moment from "moment";
import { useTranslation } from "react-i18next";

const VaccinationListScreen = () => {
  
  const [groupedVaccinations, setGroupedVaccinations] = useState<{
    [id: string]: VaccinationModel[];
  }>({});

  const {
    data: vaccinations,
    isLoading
  } = useResources<VaccinationModel>(ResourceType.VACCINATIONS);

  useEffect(() => {
    if (!vaccinations) return;
    setGroupedVaccinations(
      [...vaccinations]
        .sort((a, b) => (a.date! > b.date! ? -1 : 1))
        .reduce((grouped: { [id: string]: VaccinationModel[] }, vaccine) => {
          let key = vaccine.indications!;
          if (!grouped[key]) {
            grouped[key] = [];
          }
          grouped[key].push(vaccine);
          return grouped;
        }, {})
    );
  }, [vaccinations]);

  enum VaccinationStatusEnum {
    OK = "ok",
    BOOSTER_NEEDED = "booster_needed",
    EXPIRED = "expired",
    UNKOWN = "unknown",
  }

  function checkRules(
    key: string,
    vaccines: VaccinationModel[]
  ): VaccinationStatusEnum {
    if (!vaccines.length) return VaccinationStatusEnum.UNKOWN;
    switch (key) {
      case "hepatitisa":
        if (vaccines.length < 2) {
          return VaccinationStatusEnum.BOOSTER_NEEDED;
        }
        break;
      case "seasonalinfluenza":
        if (
          !moment(vaccines[0].date, "YYYYMMDD").isBefore(
            moment().subtract(1, "year")
          )
        ) {
          return VaccinationStatusEnum.EXPIRED;
        }
        break;
    }
    return VaccinationStatusEnum.UNKOWN;
  }

  const { t } = useTranslation();

  function translateIndication(key: string) {
    return key
      .split(",")
      .map((indication) =>
        t(`patient-vaccine.code.${indication.toUpperCase().trim()}`)
      )
      .join(", ");
  }

  return (
    <>
      <Typography.h1>{t("menu.vaccination") ?? ""}</Typography.h1>
      <Spacing />

      {isLoading ? (
        <SectionShimmer />
      ) : (
        Object.entries(groupedVaccinations)
          .sort()
          .map(([key, vaccines]) => {
            return (
              <Section
                title={translateIndication(key)}
                icon={
                  checkRules(key, vaccines) ===
                  VaccinationStatusEnum.UNKOWN ? undefined : (
                    <Avatar
                      small
                      icon={<img src={Assets.icons!.warning} alt="warning" />}
                      tint={Tints.YELLOW_LIGHT}
                    />
                  )
                }
                info={
                  checkRules(key, vaccines) === VaccinationStatusEnum.UNKOWN
                    ? undefined
                    : "1 rappel annuel est recommandé pour cette vaccination, nous vous conseillons de prendre rendez-vous avec votre médecin."
                }
              >
                <EzoList
                  data={vaccines}
                  icon={(item: VaccinationModel) => (
                    <Avatar
                      square
                      tint={Tints.YELLOW}
                      icon={
                        <Icon
                          asset={Icons.medication.syringe_xs}
                          color="#660"
                        />
                      }
                    />
                  )}
                  details={(item: VaccinationModel) => item.name!}
                  title={(item: VaccinationModel) =>
                    formatDateAsMonthAndYear(item.date!)
                  }
                />
              </Section>
            );
          })
      )}
    </>
  );
};

export default VaccinationListScreen;
