import { FetchBaseQueryError, createApi, fetchBaseQuery } from "@reduxjs/toolkit/query/react";
import { AgendaItemModel } from "../models/AgendaItemModel";
import { HCPModel } from "../models/HCPModel";
import { MedicationModel } from "../models/MedicationModel";
import { DiagnosticReportModel } from "../models/DiagnosticReportModel";
import { VaccinationModel } from "../models/VaccinationModel";
import { PrescriptionModel } from "../models/PrescriptionModel";
import { ConnectorProvider } from "../connectors/ConnectorProvider";
import { RootState } from "../store";
import { ResourceType } from "../types/ResourceType";
import { DataConnectorInterface } from "../connectors/DataConnectorInterface";

type Resource =
  | AgendaItemModel
  | MedicationModel
  | DiagnosticReportModel
  | HCPModel
  | VaccinationModel
  | PrescriptionModel;

  


export const healthDataApi = createApi({
  reducerPath: "healthDataApi",
  baseQuery: fetchBaseQuery({ baseUrl: "http://localhost:3001/" }),
  refetchOnMountOrArgChange: false,
  tagTypes: Object.values(ResourceType),

  endpoints: (builder) => ({
    // HEALTHDATA ENDPOINTS

    markResourceAsViewed: builder.mutation<boolean, {resourceId: string, resourceType: ResourceType}>({
     
      queryFn: async ({resourceId, resourceType}) => {
        const connectors = ConnectorProvider.getAll<DataConnectorInterface>();
        for (const connector of connectors) {
          await connector.markResourceAsViewed(resourceId, resourceType);
        }

        return { data: true };
      },

      invalidatesTags: (result, error, {resourceType}) => [resourceType],
    }),

    getResources: builder.query<Resource[], ResourceType>({
      queryFn: async (type, api, options, ezoQuery) => {
        // const a = (await ezoQuery(type)) as unknown as Resource[];
        const c = ConnectorProvider.getAll<DataConnectorInterface>();

       
        try {
          const resources = (
            await Promise.all(
              c.map((connector) => {
                return connector[`${type}s`]();
              })
            )
          ).flat(); // .concat(a.data as Resource[] ?? []);
  
          return { data: resources};
        } catch(e){
          // eslint-disable-next-line no-throw-literal
          throw { error:e as FetchBaseQueryError };
        }

       
      },
      providesTags: (result, error, type) => [type],
    }),

    getResourceById: builder.query<
      Resource | undefined,
      { type: ResourceType; id: string | undefined }
    >({
      queryFn: async ({ type, id }, api, options, ezoQuery) => {
        if (!id) return { data: undefined };
        const method = type as keyof DataConnectorInterface;
        const c = ConnectorProvider.getAll<DataConnectorInterface>();

        for (const connector of c) {
          try {
            const data = ConnectorProvider.isMethodValid(connector, method)
              ? await connector[method](id)
              : undefined;
            if (data) {
              return { data };
            }
          } catch (e) {
            console.log("Can't find resource");
          }
        }

        return { error: { status: 404, data: undefined } };
      },
    }),
  }),
});

export const { useGetResourcesQuery, useGetResourceByIdQuery, useMarkResourceAsViewedMutation } = healthDataApi;

export const selectResourcesOfType = (state: RootState, type: ResourceType) =>
  healthDataApi.endpoints.getResources.select(type)(state).data;