import Button from "../../../../ui/components/Button";
import Typography from "../../../../ui/components/Typography/Typography";

import Icon from "../../../../ui/components/Icon/Icon";
import { Icons } from "../../../../ui/assets";
import ButtonGroup from "../../../../ui/components/Layout/ButtonGroup";
import { Size } from "../../../../ui/tokens";
import Spacing from "../../../../ui/components/Layout/Spacing";

const DownloadKeyScreen = () => {


  return (
    <div style={{padding:Size.M}}>
      
    <Icon illustration asset={Icons.illustrations.downloading} />
    <Spacing/>
      <Typography.h2 align="center">Votre clé d'accès</Typography.h2>
      <Spacing/>
      <Typography.body align="center">
      Pour garantir la sécurité de vos données, celles-ci sont chiffrées de bout en bout, de telle sorte que seuls vos soignants et vous-même, puissiez y accéder.
      </Typography.body>

      <Typography.body align="center">
      Pour cela, nous venons de générer et d'enregistrer sur cet appareil une clé. 
      </Typography.body>
      <Typography.body align="center">
        Nous vous conseillons de conserver une copie
        de celle-ci en lieu sûr, pour un accès depuis
        d’autres appareils.
      </Typography.body>

      <ButtonGroup>
        <Button title="J'ai compris"  />
        </ButtonGroup>

    </div>
  );
};

export default DownloadKeyScreen;
