import { ReactComponent as home } from "./navigation/menu.home.svg";

import { ReactComponent as agenda } from "./navigation/menu.agenda.svg";
import { ReactComponent as treatment } from "./navigation/menu.treatment.svg";
import { ReactComponent as docs } from "./navigation/menu.docs.svg";
import { ReactComponent as settings } from "./navigation/menu.settings.svg";
import { ReactComponent as allergies } from "./navigation/menu.allergies.svg";
import { ReactComponent as vaccination } from "./navigation/menu.vaccination.svg";
import { ReactComponent as team } from "./navigation/menu.team.svg";

import { ReactComponent as home_selected } from "./navigation/menu.home-selected.svg";
import { ReactComponent as agenda_selected } from "./navigation/menu.agenda-selected.svg";
import { ReactComponent as treatment_selected } from "./navigation/menu.treatment-selected.svg";
import { ReactComponent as docs_selected } from "./navigation/menu.docs-selected.svg";
import { ReactComponent as settings_selected } from "./navigation/menu.settings-selected.svg";
import { ReactComponent as allergies_selected } from "./navigation/menu.allergies-selected.svg";
import { ReactComponent as vaccination_selected } from "./navigation/menu.vaccination-selected.svg";
import { ReactComponent as team_selected } from "./navigation/menu.team-selected.svg";

import { ReactComponent as flower } from "./navigation/flower.svg";
import { ReactComponent as chevron } from "./navigation/chevron.svg";
import { ReactComponent as logo } from "./navigation/logo.svg";
import { ReactComponent as logo_small } from "./navigation/logo-small.svg";
import { ReactComponent as hamburger } from "./navigation/menu.svg";
import { ReactComponent as filter } from "./navigation/filter.svg";

import { ReactComponent as logout } from "./navigation/logout.svg";

// icons
import { ReactComponent as pin_xs } from "./icons/location-xs.svg";
import { ReactComponent as clock_xs } from "./icons/clock-xs.svg";
import { ReactComponent as calendar_xs } from "./icons/calendar-xs.svg";
import { ReactComponent as landline_xs } from "./icons/landline-xs.svg";
import { ReactComponent as mobile_xs } from "./icons/mobile-xs.svg";
import { ReactComponent as email_xs } from "./icons/email-xs.svg";
import { ReactComponent as prescription_xs } from "./icons/prescription-xs.svg";
import { ReactComponent as prescription_fill } from "./icons/prescription-fill.svg";
import { ReactComponent as send } from "./icons/send_email.svg";

import { ReactComponent as landline_fill } from "./icons/landline-fill.svg";
import { ReactComponent as location_fill } from "./icons/location-fill.svg";
import { ReactComponent as calendar_fill } from "./icons/calendar-fill.svg";

import { ReactComponent as search } from "./icons/search-xs.svg";
import { ReactComponent as settings_fill } from "./icons/settings-xs.svg";
import { ReactComponent as heart_fill } from "./icons/heart-xs.svg";
import { ReactComponent as info } from "./icons/info-xs.svg";
import { ReactComponent as exam } from "./icons/exam-xs.svg";
import { ReactComponent as message } from "./icons/message.svg";
import { ReactComponent as form } from "./icons/form.svg";

import warning from "./icons/warning.png";

// medication
import { ReactComponent as default_xs } from "./medication/default-xs.svg";
import { ReactComponent as comp_xs } from "./medication/comp-xs.svg";
import { ReactComponent as pill_xs } from "./medication/pill-xs.svg";
import { ReactComponent as cream_xs } from "./medication/cream-xs.svg";
import { ReactComponent as syringe_xs } from "./medication/syringe-xs.svg";
import { ReactComponent as drop_xs } from "./medication/drop-xs.svg";
import { ReactComponent as bottle_xs } from "./medication/bottle-xs.svg";

//specialties
import { ReactComponent as heart } from "./specialties/heart.svg";
import { ReactComponent as lab } from "./specialties/lab.svg";
import { ReactComponent as blood } from "./specialties/drop.svg";
import { ReactComponent as document } from "./specialties/document.svg";
import { ReactComponent as virus } from "./specialties/virus.svg";
import { ReactComponent as brain } from "./specialties/brain.svg";
import { ReactComponent as ear } from "./specialties/ear.svg";
import { ReactComponent as syringe } from "./specialties/syringe.svg";
import { ReactComponent as eye } from "./specialties/eye.svg";
import { ReactComponent as tongue } from "./specialties/tongue.svg";
import { ReactComponent as imagery } from "./specialties/imagery.svg";
import { ReactComponent as bones } from "./specialties/bones.svg";
import { ReactComponent as stomach } from "./specialties/stomach.svg";
import { ReactComponent as tooth } from "./specialties/tooth.svg";
import { ReactComponent as lungs } from "./specialties/lungs.svg";
import { ReactComponent as physio } from "./specialties/physio.svg";
import { FunctionComponent } from "react";

import { ReactComponent as confirm_agenda } from "./illustrations/confirm_agenda.svg";
import { ReactComponent as downloading } from "./illustrations/download_key.svg";
import { ReactComponent as connecting } from "./illustrations/connecting.svg";
import { ReactComponent as launch } from "./illustrations/logo.svg";

interface AssetsList {
  [key: string]: string;
}



interface AssetsInterface {
  navigation: AssetsList;
  icons?: AssetsList;
  actions?: AssetsList;
  medication?: AssetsList;
  specialties?: AssetsList;
  empty: AssetsList;

  // ... other asset categories
}

interface NavigationIcons {
  agenda: FunctionComponent;
  treatment: FunctionComponent;
  docs: FunctionComponent;
  settings: FunctionComponent;
  allergies: FunctionComponent;
  home: FunctionComponent;
  vaccination: FunctionComponent;
  team: FunctionComponent;
  home_selected: FunctionComponent;
  agenda_selected: FunctionComponent;
  treatment_selected: FunctionComponent;
  docs_selected: FunctionComponent;
  settings_selected: FunctionComponent;
  allergies_selected: FunctionComponent;
  vaccination_selected: FunctionComponent;
  team_selected: FunctionComponent;
  filter: FunctionComponent;
  hamburger: FunctionComponent;
  flower: FunctionComponent;
  logo: FunctionComponent;
  logo_small: FunctionComponent;
  chevron: FunctionComponent;
  logout: FunctionComponent;
}

interface StandardIcons {
  pin_xs: FunctionComponent;
  clock_xs: FunctionComponent;
  calendar_xs: FunctionComponent;
  landline_xs: FunctionComponent;
  mobile_xs: FunctionComponent;
  email_xs: FunctionComponent;
  prescription_xs: FunctionComponent;
  prescription_fill: FunctionComponent;
  send: FunctionComponent;
  search: FunctionComponent;
  info: FunctionComponent;
  settings_fill: FunctionComponent;
  heart_fill: FunctionComponent;
  landline_fill: FunctionComponent;
  calendar_fill: FunctionComponent;
  location_fill: FunctionComponent;
  exam: FunctionComponent;
  message: FunctionComponent;
  form: FunctionComponent;
  warning: string;
}

interface MedicationIcons {
  default_xs: FunctionComponent;
  comp_xs: FunctionComponent;
  pill_xs: FunctionComponent;
  cream_xs: FunctionComponent;
  syringe_xs: FunctionComponent;
  drop_xs: FunctionComponent;
  bottle_xs: FunctionComponent;
}

interface SpecialtyIcons {
  heart: FunctionComponent;
  lab: FunctionComponent;
  blood: FunctionComponent;
  document: FunctionComponent;
  virus: FunctionComponent;
  brain: FunctionComponent;
  ear: FunctionComponent;
  syringe: FunctionComponent;
  eye: FunctionComponent;
  tongue: FunctionComponent;
  imagery: FunctionComponent;
  bones: FunctionComponent;
  stomach: FunctionComponent;
  tooth: FunctionComponent;
  lungs: FunctionComponent;
  physio: FunctionComponent;
}

interface IllustrationIcons {
  confirm_agenda: FunctionComponent;
  downloading: FunctionComponent;
  connecting: FunctionComponent;
  launch: FunctionComponent;
}

interface IconsInterface {
  navigation: NavigationIcons;
  icons: StandardIcons;
  medication: MedicationIcons;
  specialties: SpecialtyIcons;
  illustrations: IllustrationIcons;
}

const Icons: IconsInterface = {
  navigation: {
    agenda,
    treatment,
    docs,
    settings,
    allergies,
    home,
    vaccination,
    team,

    home_selected,
    agenda_selected,
    treatment_selected,
    docs_selected,
    settings_selected,
    allergies_selected,
    vaccination_selected,
    team_selected,

    filter,
    hamburger,
    flower,
    logo,
    logo_small,
    chevron,
    logout,
  },
  icons: {
    pin_xs,
    clock_xs,
    calendar_xs,
    landline_xs,
    mobile_xs,
    email_xs,
    prescription_xs,
    prescription_fill,
    send,
    search,
    info,
    settings_fill,
    heart_fill,

    landline_fill,
    calendar_fill,
    location_fill,

    exam,
    message,
    form,
    warning,
  },
  medication: {
    default_xs,
    comp_xs,
    pill_xs,
    cream_xs,
    syringe_xs,
    drop_xs,
    bottle_xs,
  },
  specialties: {
    heart,
    lab,
    blood,
    document,
    virus,
    brain,
    ear,
    syringe,
    eye,
    tongue,
    imagery,
    bones,
    stomach,
    tooth,
    lungs,
    physio,
  },

  illustrations: {
    confirm_agenda,
    downloading,
    connecting,
    launch
  },
};


const Assets: AssetsInterface = {
  navigation: {
    launch: require("./navigation/launch.png"),
    launch2: require("./navigation/launch-2.png"),
  },
  icons: {
    warning,
  },

  empty: {
    default: require("./empty-states/default.png"),
    agenda: require("./empty-states/calendar.png"),
    medication: require("./empty-states/medication.png"),
    messages: require("./empty-states/messages.png"),
  },
};

export default Assets;

export { Icons };
