import Typography from "../Typography/Typography";

import { Color, Size, Tint } from "../../tokens";
import styled from "styled-components";
import { ReactElement } from "react";
import Assets, { Icons } from "../../assets";
import Icon from "../Icon/Icon";
import SectionHeading from "./SectionHeading";

interface Props {
  title?: string;
  icon?: JSX.Element;
  background?: Color;

  info?: string;
  children: ReactElement | Array<ReactElement | undefined | boolean>;
}

const SectionContainer = styled.div`
  margin: 0 0 ${Size.S}px 0;
  padding: ${Size.XS}px;
  overflow: visible;
`;

const Content = styled.div<{ color?: Color }>`
  margin: 0 -${Size.S}px;
  border-radius: ${Size.M}px;
  padding: 0 16px;
  background-color: ${({ color }) => color ?? Color.WHITE};
  /* border:1px solid ${Color.NEUTRAL_200};*/
`;

const ExtraInfo = styled.div`
  margin-top: ${Size.S}px;
  margin-bottom: ${Size.M}px;
  display: flex;
  gap: ${Size.S}px;
  > span {
    display: inline;
    margin-top: ${Size.XS}px;
  }
`;

export default function Section({
  children,
  title,
  icon,
  info,
  background,
}: Props) {
  return (
    <SectionContainer className="section">
      <SectionHeading title={title} icon={icon} />
      <Content color={background}>{children}</Content>
      {!!info && (
        <ExtraInfo>
          {/*<Icon asset={Icons.icons.info}/>*/}
          <Typography.small>{info}</Typography.small>
        </ExtraInfo>
      )}
    </SectionContainer>
  );
}
