import { useTranslation } from "react-i18next";
import {
  MedicationAdministrationRate,
  MedicationModel,
} from "../../models/MedicationModel";
import Section from "../../ui/components/Layout/Section";
import EzoList from "../../ui/components/List/EzoList";
import { MedicationItemRenderer } from "./MedicationItemRenderer";
import DisplayModeProps from "./DisplayModeProps";
import Avatar from "../../ui/components/Avatar/Avatar";
import Icon from "../../ui/components/Icon/Icon";
import Assets, { Icons } from "../../ui/assets";
import { Color, Size, Tints } from "../../ui/tokens";
import { formatDate } from "../../utils/formatting";
import Typography from "../../ui/components/Typography/Typography";

export default function LogicalModeDisplay({
  meds,
  prescriptions,
  showDetails,
}: DisplayModeProps) {
  const singleRegimen = meds?.filter((med) =>
    med?.regimen?.every((r) => r.prescriber === med.hcp)
  );
  const mixedRegimen = meds?.filter(
    (med) => !med?.regimen?.every((r) => r.prescriber === med.hcp)
  );

  const chronic = singleRegimen?.filter((med) => med.isChronic);
  const acute = singleRegimen?.filter((med) => !med.isChronic);

  const daily = chronic?.filter(
    (med) => med.frequency === MedicationAdministrationRate.DAILY
  );
  const other = chronic?.filter(
    (med) => med.frequency !== MedicationAdministrationRate.DAILY
  );

  const { t } = useTranslation();

  return (
    <>
      {!!(mixedRegimen?.length! > 0) && (
        <Section title={t("treatment.mixed") as string}>
          <EzoList
            data={mixedRegimen}
            {...MedicationItemRenderer}
            iconRight={(item) => {
              return (
                <Avatar
                  small
                  icon={<img src={Assets.icons!.warning} />}
                  tint={Tints.YELLOW_LIGHT}
                />
              );
            }}
            onItemSelection={showDetails}
          />
        </Section>
      )}
      {!!(acute?.length! > 0) && (
        <Section title={t("treatment.acute") as string}>
          <EzoList
            data={acute}
            {...MedicationItemRenderer}
            details={(med) =>
              `${med.instruction ?? ""} ${
                med.endMoment
                  ? `(${t("helpers.until")} ${formatDate(med.endMoment, true)})`
                  : ""
              }`
            }
            iconRight={(item) => {
              const p = prescriptions?.find((p) => p.medicationId === item.id);
              return p ? (
                <Icon asset={Icons.icons.prescription_xs} />
              ) : undefined;
            }}
            onItemSelection={showDetails}
          />
        </Section>
      )}
      {!!(daily?.length! > 0) && (
        <Section title={t("treatment.daily") as string}>
          <EzoList
            data={daily}
            {...MedicationItemRenderer}
            iconRight={(item) => {
              const p = prescriptions?.find((p) => p.medicationId === item.id);
              return p ? (
                <Icon asset={Icons.icons.prescription_xs} color={Color.WHITE} />
              ) : undefined;
            }}
            onItemSelection={showDetails}
          />
        </Section>
      )}

      {!!(other?.length! > 0) && (
        <Section title={t("treatment.chronic") as string}>
          <EzoList
            data={other}
            {...MedicationItemRenderer}
            iconRight={(item) => {
              const p = prescriptions?.find((p) => p.medicationId === item.id);
              return p ? (
                <Avatar
                  square
                  small
                  icon={
                    <Icon
                      asset={Icons.icons.prescription_xs}
                      color={Color.WHITE}
                    />
                  }
                  tint={Tints.PINK}
                />
              ) : undefined;
            }}
            onItemSelection={showDetails}
          />
        </Section>
      )}
    </>
  );
}
