import Typography from "../../ui/components/Typography/Typography";

import useResources from "../../hooks/useResources";
import { MedicationModel,} from "../../models/MedicationModel";
import Assets from "../../ui/assets";
import SectionShimmer from "../../ui/components/Loading/SectionShimmer";
import EmptyState from "../../ui/components/EmptyState";
import {useNavigate} from "react-router-dom";
import {PrescriptionModel} from "../../models/PrescriptionModel";

import useDisplayOption from "../../hooks/useDisplayOption";
import LogicalModeDisplay from "./LogicalModeDisplay";
import ChronologicallModeDisplay from "./ChronologicalModeDisplay";
import {useToast} from "../../ui/components/Toast/useToast";
import {useEffect} from "react";
import {ToastTypeEnum} from "../../ui/components/Toast/ToastContext";
import {useTranslation} from "react-i18next";
import {ResourceType} from "../../types/ResourceType";
import useIsCurrentRoute from "../../hooks/useIsCurrentRoute";
import {MedicationDisplayModes} from "../navigation/DisplayOptions";
import {FlowScreenProps} from "../../ui/components/ScreenFlow/ScreenFlow";
import FilterButton from "../../ui/components/FilterButton";
import {useFlowContext} from "../../ui/components/ScreenFlow/FlowContext";
import {useMarkResourceAsViewedMutation} from "../../api/healthdata";

const MedicationListScreen = ({name}:FlowScreenProps) => {
  const navigate = useNavigate();
  const addToast = useToast();

  const isScreenVisible = useIsCurrentRoute("/medication");

  const {setActions} = useFlowContext();

  setActions(
    name,
    <FilterButton/>);

  const [displayMode] = useDisplayOption("medication.grouping");

  const {
    data: meds,
    isLoading,
    error
  } = useResources<MedicationModel>(ResourceType.MEDICATIONS, isScreenVisible);

  const { data: prescriptions } = useResources<PrescriptionModel>(
    ResourceType.PRESCRIPTIONS,
    isScreenVisible
  );

  


  function uniqueVMPs(meds: MedicationModel[]) {
    const uniques: { [id: string]: MedicationModel } = {};

    if (!meds) return [];

    for (const med of meds) {
      const vmp = med.vmp ?? med.id;
      
      if (!uniques[vmp]) {
        uniques[vmp] = { ...med };
      } else {
        uniques[vmp].instruction = "Plusieurs posologies ";
        uniques[vmp].regimen = [...uniques[vmp].regimen].concat(med.regimen);
        //uniques[vmp].id =  uniques[vmp].id + "--" + med.id;
      }
    }

    return Object.values(uniques); //.filter(med=> !med.regimen.every((r) => r.prescriber === med.hcp));
  }

  const { t } = useTranslation();

  useEffect(() => {
    if (error) {
      addToast({
        message: "Erreur lors du chargement des médicaments",
        active: true,
        type: ToastTypeEnum.ERROR,
      });
    }
  }, [error, addToast]);

  const [markResourceAsViewed] = useMarkResourceAsViewedMutation();

  return (
    <>
      <Typography.h1>{t("menu.medication") ?? ""}</Typography.h1>
      {isLoading ? (
        <SectionShimmer />
      ) : meds?.length === 0 ? (
        <EmptyState
          title="No medication to display"
          image={Assets.empty.medication}
        />
      ) : displayMode.value === MedicationDisplayModes.LOGICAL ? (
        <LogicalModeDisplay
          meds={uniqueVMPs(meds)}
          prescriptions={prescriptions}
          showDetails={(med) => {
            markResourceAsViewed({resourceId: med.id, resourceType: ResourceType.MEDICATIONS});
              navigate("./" + med.id)}
              }
        />
      ) : (
        <ChronologicallModeDisplay
          meds={uniqueVMPs(meds)}
          prescriptions={prescriptions}
          showDetails={(med) => {
              markResourceAsViewed({resourceId: med.id, resourceType: ResourceType.MEDICATIONS});
              navigate("./" + med.id)
        }}
        />
      )}
    </>
  );
};

export default MedicationListScreen;
