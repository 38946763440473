import Typography from "../../ui/components/Typography/Typography";
import SectionShimmer, {ListShimmer,} from "../../ui/components/Loading/SectionShimmer";
import useResources from "../../hooks/useResources";
import {mostRecent, mostRecentlyShared,} from "../../models/DocumentReference";
import {useNavigate} from "react-router-dom";
import Assets from "../../ui/assets";
import {DocumentRenderer} from "./DocumentRenderer";
import EmptyState from "../../ui/components/EmptyState";
import Badge from "../../ui/components/Badge";
import {useTranslation} from "react-i18next";
import {DocumentResource, DocumentType} from "../../models/DocumentResource";
import useDisplayOption from "../../hooks/useDisplayOption";
import Section from "../../ui/components/Layout/Section";
import EzoList from "../../ui/components/List/EzoList";
import {formatDate, getDocumentSubtitle, getDocumentTitle,} from "../../utils/formatting";
import {ResourceType} from "../../types/ResourceType";
import useIsCurrentRoute from "../../hooks/useIsCurrentRoute";
import FilterButton from "../../ui/components/FilterButton";
import {useFlowContext} from "../../ui/components/ScreenFlow/FlowContext";
import {FlowScreenProps} from "../../ui/components/ScreenFlow/ScreenFlow";
import {useMarkResourceAsViewedMutation} from "../../api/healthdata";

const DocumentsListScreen = ({name}:FlowScreenProps) => {
  const isScreenVisible = useIsCurrentRoute("/documents");

  const { data, isLoading } = useResources<DocumentResource>(
    ResourceType.DOCUMENTS,
    isScreenVisible
  );

  const {setActions} = useFlowContext();

  setActions(
    name,
    <FilterButton/>);

  const [showPrescriptions] = useDisplayOption("documents.prescriptions");
  const [showReports] = useDisplayOption("documents.reports");
  const [showResults] = useDisplayOption("documents.results");
  const [sortingOrder] = useDisplayOption("documents.sortingOrder");
  const documents = data
    ?.slice()
    .sort(
      sortingOrder.value === "SHARE_DATE" ? mostRecentlyShared : mostRecent
    ); //.filter((d) => d.type === DocumentType.MEDICATION_PRESCRIPTION);
  const visibleDocuments = documents?.filter(
    (d) =>
      (d.type === DocumentType.MEDICATION_PRESCRIPTION &&
        showPrescriptions.value) ||
      (d.type === DocumentType.REPORT && showReports.value) ||
      (d.type === DocumentType.LAB_RESULT && showResults.value) ||
      d.type === DocumentType.MESSAGE
  );

  const navigate = useNavigate();

  const [markResourceAsViewed] = useMarkResourceAsViewedMutation();

  const { t } = useTranslation();
  return (
    <>
      <Typography.h1>{t("menu.documents") ?? "Documents"}</Typography.h1>

      {visibleDocuments?.length === 0 ? (
        <EmptyState title="Nothing to see here" image={Assets.empty.messages} />
      ) : isLoading ? (
        <SectionShimmer />
      ) : (
        <>
          <Section>
            {isLoading ? (
              <ListShimmer length={5} />
            ) : data?.length === 0 ? (
              <EmptyState />
            ) : (
              <EzoList
                data={visibleDocuments}
                {...DocumentRenderer}
                title={getDocumentTitle}
                iconRight={(item) =>
                  item.viewedOn ? undefined : <Badge />
                }
                details={(item) =>
                  `${getDocumentSubtitle(item)} - ${formatDate(
                    item.issued,
                    true
                  )}`
                }
                onItemSelection={(item) => {
                  markResourceAsViewed({resourceId: item?.documentService?.id ?? item.id, resourceType: ResourceType.DOCUMENTS});
                  navigate(`${item.id}`);
                }}
              />
            )}
          </Section>
        </>
      )}
    </>
  );
};

export default DocumentsListScreen;
