import React from "react";
import Avatar, { AvatarTypes } from "./Avatar";
import { HCPModel, isHCPModel } from "../../../models/HCPModel";
import { PatientModel } from "../../../models/PatientModel";
import { Size } from "../../tokens";

interface AvatarGroupProps {
  persons?: Array<PatientModel | HCPModel>;
  large?: boolean;
  onPress?: () => void;
}

export default function AvatarGroup({
  persons,
  large,
  onPress,
}: AvatarGroupProps) {
  return (
    <div style={{ flexDirection: "row", display: "flex" }} onClick={onPress}>
      {persons?.map((p, i) => (
        <Avatar
          small={!large}
          large={large}
          extraStyle={{
            //
            opacity: 1, // i===1 ? 1 : .1,
            zIndex: 10 - i,
            marginLeft: i === 0 ? 0 : -(large ? Size.M : Size.S),
            boxShadow: "4px 0px 0px rgba(255, 255, 255, 1)",
          }}
          key={p?.id}
          person={p}
          label="..."
          type={isHCPModel(p) ? AvatarTypes.HCP : AvatarTypes.PATIENT}
        />
      ))}
    </div>
  );
}
