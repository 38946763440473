import {
  Agenda,
  Apis,
  AppointmentTypeAndPlace,
  CalendarItem,
  Code,
  CodeStub,
  ComplementFilter,
  Contact,
  ContactByHcPartyTagCodeDateFilter,
  FilterChainContact,
  FilterChainService,
  HealthcareParty,
  IccAnonymousAccessApi,
  IcureApi,
  IcureApiOptions,
  IcureBasicApi,
  IntersectionFilter,
  Patient,
  RecoveryDataUseFailureReason,
  retry,
  Service,
  ServiceByHcPartyTagCodeDateFilter,
  SubContact,
  ua2hex,
  UnionFilter,
  User,
  UserGroup,
} from "@icure/api";

import {AnonymousMedTechApi, AnonymousMedTechApiBuilder} from "@icure/medical-device-sdk";

import dayjs from "dayjs";
import {groupBy} from "../utils/utils";
import {UserCredentials} from "../../../models/UserCredentials";
import {API_TIME_FORMAT, API_URL, MSG_GW_URL, PROCESS_ID, RECOVERY_KEY, SPEC_ID,} from "../api/constants";
import {
  icureCalendarItemToAgendaItemModel,
  icureHcpToHCPModel,
  icureLabResultsContactToReportModel,
  icureLabResultsToReportModel,
  iCureMedicationServiceToMedicationModel,
  iCurePatientToPatientModel,
  iCurePrescriptionServiceToPrescriptionModel,
  icureReportToDiagnosticReport,
  icureServiceWithHCPToAgendaItemModel,
  iCureVaccinationServiceToVaccinationModel,
} from "../mappers";
import {addKeyPairToPatient, storeAndCacheKeyPair} from "../utils/crypto";
import {MessageGatewayApi} from "../utils/MessageGatewayApi";
import {AgendaItemModel} from "../../../models/AgendaItemModel";
import {PatientModel} from "../../../models/PatientModel";
import {HCPModel} from "../../../models/HCPModel";
import {MedicationModel} from "../../../models/MedicationModel";
import {CommunicationTopic, DiagnosticReportModel,} from "../../../models/DiagnosticReportModel";
import {VaccinationModel} from "../../../models/VaccinationModel";
import {PrescriptionModel} from "../../../models/PrescriptionModel";
import {DocumentContent,} from "../../../models/DocumentReference";
import {DataConnectorInterface} from "../../DataConnectorInterface";
import {DocumentResource} from "../../../models/DocumentResource";
import {IcureApiCryptoStrategies} from "./IcureCryptoStrategies";
import {ConnectorOptions} from "../../ConnectorProvider";
import {CANCELED_BY_PATIENT} from "../../../../constants/main.constants";
import {FROM_EMAIL, MESSAGE_HOST} from "../../../../services/constants";
import {IcureTokens} from "../../../../model/credentials.model";
import i18n from 'i18next';
import {IcureResourceType, ResourceType} from "../../../types/ResourceType";
import {VIEWED_ON_TAG_TYPE} from "../../../constants/common";

declare global {
  interface Window {
    iccApi: any;
    ms: MedispringConnector;
  }
}

const PLANNED_PROCEDURES_TAGS = [
  { code: "procedure", type: "CD-ITEM-EXT" },
  { code: "proposed", type: "CD-LIFECYCLE" },
];
const MEDICATION_TAGS = [
  { code: "medication", type: "CD-ITEM" },
  { code: "stopped", type: "CD-LIFECYCLE" },
];
const PRESCRIPTION_TAGS = [
  { code: "treatment", type: "CD-ITEM" },
  { code: "pendingPrescription", type: "CD-ITEM-EXT" }
];
const VACCINATION_TAGS = [
  { code: "vaccine", type: "CD-ITEM" },
  { type: "MS-VACCINEFIELD", code: "medication" },
];
const REPORT_TAGS = [
  { type: "CD-ENCOUNTER", code: "contactreport" },
  { type: "CD-TRANSACTION", code: "contactreport" },
];
const LAB_RESULTS_TAG = { type: "CD-TRANSACTION", code: "labresult" } as Code;

function getTagsForTopic(topic: CommunicationTopic) {
  switch (topic) {
    case CommunicationTopic.REPORT_LABS:
      return [LAB_RESULTS_TAG];
    default:
      return REPORT_TAGS;
  }
}

const encryptedFieldsConfig: any = {
  patient: ["note", "properties[].typedValue"],
};

const icureApiOptions: IcureApiOptions = {
  encryptedFieldsConfig: encryptedFieldsConfig,
  headers: { "X-Bypass-Session": "true" },
};

const getIcureOptions = (options: ConnectorOptions): IcureApiOptions => {
  return {
    ...icureApiOptions,
    groupSelector: async (availableGroupsInfo: UserGroup[]) => {
      console.log("groupSelector", options);
      return options.credentials?.groupId ?? availableGroupsInfo[0].groupId!;
    },
  };
};

export class MedispringConnector implements DataConnectorInterface {
  private readonly _api: Apis;
  private readonly _user: User;
  private readonly _icurePatient: Patient;
  private readonly _hcpUserId: string;

  public patient: PatientModel;
  public hcpId: string;
  public responsible: HCPModel | undefined;
  public id: string;
  public pki?: string;

  public hasValidKeys: boolean = false;

  public static newPrivateKey?: string;
  public static shouldGenerateNewKey?: boolean;

  private static _connectorsById: { [id: string]: MedispringConnector } = {};
  private static _providersById: { [id: string]: HCPModel } = {};
  private static _groupIdByHcpId: { [id: string]: string } = {};

  private static _anonymousAccessApi: IccAnonymousAccessApi | undefined;
  private static _anonymousApi: AnonymousMedTechApi | undefined;
  private static _msgGwApi: MessageGatewayApi | undefined;

  private _agendaCache: { [id: string]: Agenda } = {};

  private constructor(
    api: Apis,
    user: User,
    patient: Patient,
    hcpUserId: string,
    hcpId: string
  ) {
    this._api = api;
    this._user = user;
    this._icurePatient = patient;
    this.patient = iCurePatientToPatientModel(patient);
    this._hcpUserId = hcpUserId;
    this.hcpId = hcpId;
    this.id = user.id!;
  }

  static async getAvailableConnections(
    credentials: UserCredentials
  ): Promise<ConnectorOptions[]> {
     const auth =
      credentials.token && credentials.tokenProvider
        ? {
            thirdPartyTokens: {
              [credentials.tokenProvider]: credentials.token,
            },
          }
        : {
            username:
              credentials.userName ??
              `${credentials.groupId}/${credentials.userId}`,
            password: credentials.password!,
          };

    const api = await IcureBasicApi.initialise(API_URL, auth, undefined,{headers:{ "X-Bypass-Session": "true"}});

    const user = await api.userApi.getCurrentUser();
    
    
    // @ts-ignore next line
    const name = (api.currentGroupInfo.name ?? "").split(" ");
    // @ts-ignore next line
    const id = user.identifier[0]?.value ?? user.patientId;

    const groups = (await api.getGroupsInfo()).availableGroups;

    const recoveryKey = localStorage.getItem(RECOVERY_KEY) ?? undefined;
    if (recoveryKey) console.log("recovery key found",recoveryKey)

    return groups.map((g) => ({
      id: g.groupName!,
      info: { firstName: name[0], lastName: name[1], id },
      recoveryKey,
      credentials: {
        userId: g.userId,
        groupId: g.groupId,
        password: credentials.password,
        token: credentials.token,
        tokenProvider: credentials.tokenProvider,
      },
    }));
  }

  static getConnectorById(id: string) {
    return this._connectorsById[id];
  }

  static getGroupIdByHcpId(id: string) {
    return this._groupIdByHcpId[id];
  }

  static handleError(e: Error) {
    //Alert.show("Can't setup connecytor","Key missing blabla",[])
    //const setup = createElement(SetupFlow);
    // ModalSheet.show(setup);
  }

  /*static async getApi(login: string, token: string): Promise<Apis | undefined> {
    const api = await IcureApi.initialise(API_URL, {username:login, password:token} as AuthenticationDetails,new IcureApiCryptoStrategies(),undefined,undefined,icureApiOptions);
    return api;
  }*/

  static getMessageGatewayApi() {
    if (!this._msgGwApi) {
      this._msgGwApi = new MessageGatewayApi(MSG_GW_URL, SPEC_ID);
    }
    return this._msgGwApi;
  }

  static async getAnonymousApi() {
    if (!this._anonymousApi) {
      this._anonymousApi = await new AnonymousMedTechApiBuilder()
        .withICureBaseUrl(API_URL)
        .withMsgGwUrl(MSG_GW_URL)
        .withMsgGwSpecId(SPEC_ID)
        .withCrypto(crypto)
        .withAuthProcessByEmailId(PROCESS_ID)
        .withAuthProcessBySmsId(PROCESS_ID)
        // .preventCookieUsage()
        .build();
    }
    return this._anonymousApi;
  }

  static async getAnonymousAccessApi() {
    if (!this._anonymousAccessApi) {
      this._anonymousAccessApi = new IccAnonymousAccessApi(API_URL, { "X-Bypass-Session": "true" });
    }
    return this._anonymousAccessApi;
  }

  static async init(options: ConnectorOptions) {
    const login =
      options.credentials?.userName ??
      `${options.credentials?.groupId}/${options.credentials?.userId}`;
    const password = options.credentials?.password || "";

    const authDetails =
      options.credentials?.token && options.credentials.tokenProvider
        ? {
            thirdPartyTokens: {
              [options.credentials?.tokenProvider]: options.credentials?.token,
            },
          }
        : { username: login, password };

    //          const authDetails = {username:login, password};

    try {
      //const authApi = new IccAuthApi(API_URL,{}, undefined,fetch)
      //const authResult = await authApi.login({username:login, password:token} as AuthenticationDetails)

      const api = await IcureApi.initialise(
        API_URL,
        authDetails,
        new IcureApiCryptoStrategies(options),
        undefined,
        window.fetch,
        getIcureOptions(options)
      );

      let recoveryResult: RecoveryDataUseFailureReason | null = RecoveryDataUseFailureReason.Missing;
      if (options.recoveryKey) {
        recoveryResult =  await api.recoveryApi.recoverExchangeData(options.recoveryKey); 
      }

      

      window.iccApi = api;

      const user = await api.userApi.getCurrentUser();
      // if (debug) await debugAddKeysForKeithRichards();
      const patient = (await api.patientApi.getPatientWithUser(
        user,
        user.patientId!
      )) as Patient;



      
    

      const c = new MedispringConnector(
        api,
        user,
        patient,
        patient.preferredUserId!,
        patient.responsible!
      );

      c.responsible = await c.provider(patient.responsible!);

      await api.patientApi.shareWith(c.responsible.parentId ?? c.responsible.id!,patient,(await api.patientApi.decryptSecretIdsOf(patient))) 
      await api.accessLogApi.createAccessLogWithUser(user, 
        await api.accessLogApi.newInstance(
          user,patient,{
        accessType:"USER_ACCESS",
        detail:"Log in from patient app"
      },
      {
        additionalDelegates: {
          [c.responsible.parentId ?? c.responsible.id!]: "WRITE",
        },
      })
    );
    

      if (options.recoveryKey && recoveryResult == null){
        localStorage.setItem(RECOVERY_KEY, "");
        const keys = await api.cryptoApi.getCurrentUserAvailablePublicKeysHex(true)
        if (keys){
          const keyPair = await api.cryptoApi.getKeyPairForFingerprint(keys[0].slice(-32))
          if (keyPair) {
            const privateKey = ua2hex(await api.cryptoApi.primitives.RSA.exportKey(keyPair.pair.privateKey,"pkcs8"))
            c.patient.pki = privateKey;
          }
        }

      } 
      
      

      /*
```
curl -k -v -u msadmin@icure.cloud:dhP84un#6JPD5tA@AE 'https://api.icure.cloud/rest/v1/group/ms-testapppatient-prisederendezvous-e81b9d0b-f732-4c52-8186-6f35eb451c2b/name/Cabinet%20Medispring' -X 'PUT' -H 'Content-Type: application/json'

      */

      //api.groupApi.modifyGroupName("ms-testapppatient-prisederendezvous-e81b9d0b-f732-4c52-8186-6f35eb451c2b","Cabinet Medispring")

      this._connectorsById[patient.id!] = c;
      this._connectorsById[patient.responsible!] = c;
      this._groupIdByHcpId[patient.responsible!] = user.groupId!;

      return c;
    } catch (e) {
      throw e;
    }
  }

  /*
  static async updateTokenForUsers(
    users: User[],
    shortToken: string,
    longToken: string
  ): Promise<UserCredentials[]> {
    const allCredentials = awaitSequentially(
      users,
      async (userGroup: UserGroup) => {
        const api = await IcureApi.initialise(
          API_URL,
          `${userGroup.groupId}/${userGroup.userId}`,
          shortToken
        );

        if (!api) return; // {error:{error: 'Cant instantiate API'} as FetchBaseQueryError}
        const user = await api.userApi?.getCurrentUser();

      //  const passwordHash = await api.userApi.encodePassword(longToken);
        await api.userApi.modifyUser({ ...user, passwordHash:longToken });


        // Patient stuff
        // await setupPatientCrypto(currentUser);

        return {
          userId: userGroup.userId!,
          groupId: userGroup.groupId!,
          token: longToken,
        };
      }
    );
    const credentialsList = (await allCredentials).filter(
      (c) => c !== undefined
    ) as UserCredentials[];
    return credentialsList;
  }
  */

  // appointment
  async appointments(): Promise<AgendaItemModel[]> {
    const appointments = await this._api?.calendarItemApi.getCalendarItemsWithPaginationWithUser(
      this._user    );
    const procedures = await this.getPlannedProcedures();
    if (!appointments.rows || appointments.rows?.length === 0) return procedures;

    return (
      await Promise.all(
        appointments
          .rows?.filter((ci: CalendarItem) => !ci.allDay) // exclude all day events because they are often used as todos
          .map(async (ci: CalendarItem) => {

            const cachedAgenda = this._agendaCache[ci.agendaId!];
           const agendaPromise = cachedAgenda ??  this._api?.agendaApi.getAgenda(ci.agendaId!);
           this._agendaCache[ci.agendaId!] = agendaPromise;
           const agenda = await agendaPromise;
            const hcp = await this.provider(agenda.responsible!);
            return { ci, patient: this.patient, hcp };
          })
      )
    )
      .map(icureCalendarItemToAgendaItemModel)
      .concat(procedures);
  }

  async getPlannedProcedures(): Promise<AgendaItemModel[]> {
    try {
      const services = await this._getServicesMatchingTags(
        PLANNED_PROCEDURES_TAGS
      );
      if (services.length === 0) return [];
      return (
        await Promise.all(
          services
            .sort((sA: Service, sB: Service) =>
              sA.valueDate! > sB.valueDate! ? 1 : -1
            )
            .map(async (s) => {
              const hcp = s.content!.requester?.stringValue
                ? await this.provider(s.content!.requester?.stringValue)
                : null;
              return {
                service: s,
                patient: this.patient,
                hcp, // : hcp ? icureUserHcpToHCPModel(hcp) : undefined,
              };
            })
        )
      ).map(icureServiceWithHCPToAgendaItemModel);
    } catch (e) {
      return [];
    }
  }

  async appointment(id: string): Promise<AgendaItemModel | undefined> {
    const appointment =
      await this._api?.calendarItemApi.getCalendarItemWithUser(this._user, id);
    if (!appointment) return undefined;
    const agenda = await this._api?.agendaApi.getAgendasForUser(this._user.id!);
    const hcp = await this.provider(agenda.responsible!);
    return icureCalendarItemToAgendaItemModel({
      ci: appointment,
      hcp,
      patient: this.patient,
    });
  }
  async getPatient(): Promise<PatientModel> {
    const p = await this._api.patientApi.getPatientWithUser(
      this._user,
      this._icurePatient.id!
    );
    return iCurePatientToPatientModel(p);
  }

  async medications(): Promise<MedicationModel[]> {
    const services = await this._getServicesMatchingTags(MEDICATION_TAGS, true);
//    await this._api.contactApi.getServiceWithUser(this._user,"38b12dfc-22b5-4f03-9768-903a581e3a8d");
    //const services = [med]
    // await this._getServicesMatchingTags(MEDICATION_TAGS, true);
    

    return services
      .filter(
        (s) =>
          !s.content?.medication?.medicationValue?.endMoment ||
          s.content?.medication?.medicationValue?.endMoment >
            parseInt(dayjs().format("YYYYMMDD"))
      )
      .map((s) => iCureMedicationServiceToMedicationModel(s, this._icurePatient.id!));
  }

  async medication(id: string): Promise<MedicationModel | undefined> {
   
    const list = await this._api?.contactApi.listServicesWithUser(this._user, {
      ids: [id],
    });
    if (!list) return undefined;

    return iCureMedicationServiceToMedicationModel(list[0], this._icurePatient.id!);
  }

  async prescriptions(): Promise<PrescriptionModel[]> {
    const prescriptions = await this._getServicesMatchingTags(
      PRESCRIPTION_TAGS
    );
    return prescriptions.map(iCurePrescriptionServiceToPrescriptionModel);
  }

  async prescription(id: string): Promise<PrescriptionModel | undefined> {
    const contacts = await this._api?.contactApi.findByHCPartyFormIdWithUser(
      this._user,
      this._icurePatient.id!,
      id
    );
    const services = (contacts as Contact[]).flatMap(
      (ctc) => ctc.services
    ) as Service[];

    const prescription = services.find(
      (s) => s.tags?.some((tag) => tag.code === "treatment") && s.formId === id
    );
    if (!prescription) return undefined;
    return iCurePrescriptionServiceToPrescriptionModel(prescription);
  }

  async vaccinations(): Promise<VaccinationModel[]> {
    const services = await this._getServicesMatchingTags(VACCINATION_TAGS);
    return services.map(iCureVaccinationServiceToVaccinationModel);
  }

  async documents(): Promise<DocumentResource[]> {
    const prescriptions = await this._getServicesMatchingTags(
      PRESCRIPTION_TAGS,
      true
    );

    const reports =
      ((await this._getDocumentsForTopic(
        CommunicationTopic.SUMMARY_REPORT
      )) as DocumentResource[]) ?? [];
    const results =
      ((await this._getDocumentsForTopic(
        CommunicationTopic.REPORT_LABS
      )) as DocumentResource[]) ?? [];

    return await this._joinWithHcp(
      reports
        .concat(prescriptions.map(iCurePrescriptionServiceToPrescriptionModel))
        .concat(results)
    );
  }

  async document(id: string): Promise<DocumentResource> {
    const contacts = await this._api?.contactApi.findByHCPartyFormIdWithUser(
      this._user,
      this._icurePatient.id!,
      id
    );
    return {
      id,
      issued: contacts[0].closingDate,
      hcp: contacts[0].responsible,
    };
  }

  async media(id: string): Promise<DocumentContent> {
    const doc = await this._api?.documentApi.getDocument(id);
    const type = await this._api?.documentApi.mimeType(doc?.mainUti!);
    /*const keys =
      await this._api?.cryptoApi.extractKeysFromDelegationsForHcpHierarchy(
        this._icurePatient.id!,
        doc.id!,
        doc.encryptionKeys!
      );
    */
   // const enckeys = [doc,keys];

   // getAndTryDecryptMainAttachmentAs

   let attachment = await this._api?.documentApi.getAndTryDecryptMainAttachmentAs(
    doc,
    "application/octet-stream"
   );

   if (!attachment) {

    attachment = await this._api?.documentApi.getAttachmentAs(
      id,
      doc?.attachmentId!,
      "application/octet-stream",
      ''
      //keys.extractedKeys.join(",")
    );
  }
    /*
    const url = await this._api?.documentApi.getAttachmentUrl(
      id,
      doc?.attachmentId!,
      []
    );
    */
    return {
      id,
      name: doc?.name?.length! > 0 ? doc.name : "Voir le document",
      format: type,
      attachment,
    };
  }
  async medias(): Promise<DocumentContent[]> {
    return [];
  }

  async providers(): Promise<HCPModel[]> {
    const hcp = await this.provider(this._icurePatient.responsible!);
    return [hcp];
  }

  async provider(id: string): Promise<HCPModel> {
    if (!id) return undefined as unknown as HCPModel;

    if (MedispringConnector._providersById[id])
      return MedispringConnector._providersById[id];

    const hcp = await this._api?.healthcarePartyApi?.getHealthcareParty(id);
    MedispringConnector._providersById[id] = icureHcpToHCPModel(hcp);
    return MedispringConnector._providersById[id];
  }

  async labResults(): Promise<DiagnosticReportModel[]> {
    const labResults = await this._getDocumentsForTopic(
      CommunicationTopic.REPORT_LABS
    );
    return labResults as DiagnosticReportModel[];
  }

  async labResult(id: string): Promise<DiagnosticReportModel | undefined> {
    const contacts = await this._api?.contactApi.findByHCPartyFormIdWithUser(
      this._user,
      this._icurePatient.id!,
      id
    );
    const uniques = new Map();

    const services = (contacts as Contact[])
      .flatMap((ctc) => ctc.services)
      .filter((s) => {
        if (!uniques.get(s?.id)) {
          uniques.set(s?.id, true);
          return true;
        }

        return false;
      }) as Service[];

    const ctc = {
      id,
      closingDate: contacts[0].closingDate,
      hcp: contacts[0].responsible,

      responsible: contacts[0].responsible,
      healthcarePartyId: contacts[0].healthcarePartyId,
      services,
    };

    //const ctc =  await this._api?.contactApi.getContactWithUser(this._user,id);
    //if (!ctc) return undefined;

    const author = await this.provider(contacts[0].responsible!);
    const interpreter = await this.provider(
      contacts[0].services[0].healthcarePartyId!
    );
    return {
      ...icureLabResultsContactToReportModel(ctc),
      performer: author,
      resultsInterpreter: interpreter,
    };
  }

  async importPKI(key: string) {
    await storeAndCacheKeyPair(
      this._api,
      this._icurePatient,
      this._icurePatient.publicKey!,
      key
    );

    return true;
  }

  async getNewPrivateKey() {
    const pki = await addKeyPairToPatient(
      this._api,
      this._user,
      this._icurePatient
    );

    return pki;
  }
  // MUTATIONS

  async cancelAppointment(id: string) {
    const calendarItem: CalendarItem | undefined = await this._api.calendarItemApi.getCalendarItemWithUser(this._user, id).catch(e => undefined);
    if (!calendarItem?.id) {
      console.error("Could not getCalendarItemWithUser", id);
      return false;
    }

    await this.flagCalendarItemAsCanceledByPatient(calendarItem);
    await this.cancelCalendarItemReminder(calendarItem);
    await this.sendCalendarItemCancellationConfirmationEmail(calendarItem);

    return true;
  }

  async declineProposedProcedure(serviceId: string) {
    const targetContact: Contact | undefined = await this.getContactByServiceId(serviceId);
    if (!targetContact?.id) return false;

    const targetService: Service | undefined = targetContact.services?.find((s: Service) => s.id === serviceId);
    if (!targetService?.id) return false;

    targetService.tags = targetService.tags
      ?.filter((tag: Code) => tag.type !== "CD-LIFECYCLE")
      ?.concat({ code: "refused", type: "CD-LIFECYCLE", version: '1' });

    const modifiedContact: Contact | undefined = await this._api.contactApi.modifyContactWithUser(this._user, targetContact).catch(e => undefined);

    return Boolean(modifiedContact?.id);
  }

  async sendCalendarItemCancellationConfirmationEmail(calendarItem: CalendarItem): Promise<void> {
    if (!this._user?.email) return;

    const currentHcp: HealthcareParty = await this._api.healthcarePartyApi.getHealthcareParty(calendarItem.responsible || '');
    if (!currentHcp?.id) return;

    // @ts-ignore
    const healthCarePartyUrl: string = `${this.getHost()}${encodeURI(this._api?.['currentGroupInfo']?.groupId || this._user?.groupId)}/${encodeURI(currentHcp.id || '')}`;

    const formattedHcpName: string = this.formatHealthcarePartyName(currentHcp);

    await this.sendEmail(
        this._user.email,
        i18n.t('agenda.cancel_appointment_email_subject', { name: formattedHcpName }),
        i18n.t('emails:cancellation', {
          healthCarePartyUrl: healthCarePartyUrl,
          longDate: dayjs(String(calendarItem.startTime), API_TIME_FORMAT).format('ddd DD/MM, HH:mm'),
          hcpName: formattedHcpName,
        }),
    );

  }

  async cancelCalendarItemReminder(calendarItem: CalendarItem): Promise<void> {
    const { ok, statusText }: Response = await fetch(`${MESSAGE_HOST}/ms/event/${calendarItem.id}`, {
      method: 'DELETE',
      headers: {
        'Content-Type': 'application/json',
        'Authorization': await this.getAuthorizationBearer(),
      },
    });
    if (!ok) console.error("Could not cancelCalendarItemReminder", statusText);
  }

  public getHost(): string {
    return `${window.location.protocol}//${window.location.hostname}${window.location.port ? `:${window.location.port}` : ''}/`;
  };

  public formatHealthcarePartyName({ name, firstName, lastName, civility }: HealthcareParty): string {
    return `${civility ? civility : ''} ${firstName && lastName ? `${firstName} ${lastName}` : `${name}`}`.trim();
  }

  async getAuthorizationBearer(): Promise<string> {
    const icureTokens: IcureTokens | undefined = await this.getIcureTokens();
    return 'Bearer ' + icureTokens?.token || '';
  }

  async getIcureTokens(): Promise<IcureTokens | undefined> {
    return this._api?.authApi?.authenticationProvider?.getIcureTokens();
  }

  async flagCalendarItemAsCanceledByPatient(calendarItem: CalendarItem): Promise<CalendarItem> {
    return this._api.calendarItemApi.modifyCalendarItemWithHcParty(
        this._user,
        {
          ...calendarItem,
          cancellationTimestamp: +new Date(),
          meetingTags: [
            ...calendarItem.meetingTags!,
            {
              code: CANCELED_BY_PATIENT,
              date: Number(dayjs().format(API_TIME_FORMAT)),
            },
          ],
        },
    ).catch(e => {
      console.error("Could not flagCalendarItemAsCanceledByPatient", e);
      return calendarItem;
    });
  }

  async createAppointment(
    hcp: string,
    userId: string,
    type: AppointmentTypeAndPlace,
    slot: number
  ): Promise<AgendaItemModel> {
    let item: CalendarItem;
    const agenda = await this._api?.agendaApi.getAgendasForUser(userId);

    const ci = await this._api?.calendarItemApi.newInstancePatient(
      this._user,
      this._icurePatient,
      this._buildCalendarItem(agenda?.id!, this._icurePatient, hcp, type, slot),
      {
        additionalDelegates: {
          [this._icurePatient.id!]: "WRITE",
          [hcp]: "WRITE",
        },
      }
    );

    item = await this._api?.calendarItemApi.createCalendarItemWithHcParty(
      this._user,
      ci
    );
    return icureCalendarItemToAgendaItemModel({
      ci: item,
      patient: this.patient,
      hcp: await this.provider(hcp),
    });
  }

  async getHexadecimalPrivateKeys(): Promise<string[]> {
    //const location = getKeyPairLocationInStorage(this._icurePatient.id!, this._icurePatient.publicKey!.slice(-32));
    //const keyPair = await this._api.cryptoApi.keyStorage.getKeypair(location);

    const keyPairs =
      await this._api.cryptoApi.getEncryptionDecryptionKeypairsForDataOwnerHierarchy();

    const exportedPrivateKeys: string[] = [];

    for (let kp of keyPairs.self.keys) {
      const exportedPrivateKey: string = !kp.pair?.privateKey ? '' : ua2hex(
        await this._api.cryptoApi.primitives.RSA.exportKey(
          kp.pair.privateKey,
          "pkcs8"
        )
      );
      if (exportedPrivateKey) exportedPrivateKeys.push(exportedPrivateKey);
    }
    return exportedPrivateKeys;
  }

  async askForRefill(id: string) {
    const hcp = await this.provider(this._icurePatient.responsible!);

    const notification = await this._api.maintenanceTaskApi.newInstance(
      this._user,
      {
        taskType: "PRESCRIPTION_RENEWAL_REQUEST",
      },
      {
        additionalDelegates: {
          [hcp.id]: "WRITE",
        },
      }
    );
    notification.properties = [
      {
        id: "medicationServiceId",
        type: {
          type: "STRING",
        },
        typedValue: {
          type: "STRING",
          stringValue: id,
        },
      },
    ];
    // Should be encrypted AND unfalsifiable as it could be used to retrieve the sfk-patient link or create a request for someone else
    notification.responsible = this._icurePatient.id;
    await this._api.maintenanceTaskApi.createMaintenanceTaskWithUser(
      this._user,
      notification
    );
  }

  async canUseCredentials(
    credentials: UserCredentials
  ): Promise<{ credentials: UserCredentials; groups: UserGroup[] } | null> {
    if (credentials.token && credentials.tokenProvider) {
      const api = await IcureBasicApi.initialise(API_URL, {
        thirdPartyTokens: { [credentials.tokenProvider]: credentials.token },
      });
      const groups = await api.userApi.getMatchingUsers();

      return groups?.length ? { credentials, groups } : null;
    } else {
      return null;
    }
  }

  // PRIVATE METHODS

  // TODO : accept Resources
  private async _joinWithHcp(data: DocumentResource[]) {
    return Promise.all(
      data.map(async (d) => {
        const hcp = await this.provider(
          typeof d.hcp === "string" ? d.hcp : d.hcp?.id!
        );
        return { ...d, hcp };
      })
    );
  }

  private async _getPlannedProcedures(): Promise<AgendaItemModel[]> {
    try {
      const services = await this._getServicesMatchingTags(
        PLANNED_PROCEDURES_TAGS
      );
      if (services.length === 0) return [];
      return (
        await Promise.all(
          services
            .sort((sA: Service, sB: Service) =>
              sA.valueDate! > sB.valueDate! ? 1 : -1
            )
            .map(async (s) => {
              const hcp = s.content!.requester?.stringValue
                ? await this.provider(s.content!.requester?.stringValue)
                : null;
              return {
                service: s,
                patient: this.patient,
                hcp, // : hcp ? icureUserHcpToHCPModel(hcp) : undefined,
              };
            })
        )
      ).map(icureServiceWithHCPToAgendaItemModel);
    } catch (e) {
      return [];
    }
  }

  async markResourceAsViewed(calendarItemIdOrServiceId: string, resourceType: ResourceType) {
    if (!calendarItemIdOrServiceId || !resourceType) return;

    // Either <CalendarItem> or <Contact>
    const resource: CalendarItem | Contact | undefined = await this.getResourceByIdAndType(calendarItemIdOrServiceId, resourceType);
    if (!resource?.id) return;

    // By reference (false === Icure entity did not get updated / no need to save)
    const resourceGotModified: boolean = this.addViewedOnTag(resource, calendarItemIdOrServiceId);

    // Not found / something wrong / already seen? Take no action
    if (!resourceGotModified) return;

    await this.saveResource(resource);
  }

  private async saveResource(resource: CalendarItem | Contact): Promise<CalendarItem | Contact | undefined> {
    if (!resource?.id) return;

    // Either <CalendarItem> or <Contact>
    const icureResourceType: IcureResourceType = this.getIcureResourceType(resource);

    return icureResourceType === IcureResourceType.CALENDARITEM
      ? this._api.calendarItemApi.modifyCalendarItemWithHcParty(this._user, resource).catch(e => undefined)
      : this._api.contactApi.modifyContactWithUser(this._user, resource).catch(e => undefined)
  }

  private addViewedOnTag(resource: CalendarItem | Contact, calendarItemIdOrServiceId: string): boolean {
    if (!resource?.id || !calendarItemIdOrServiceId) return false;

    // Either <CalendarItem> or <Contact>
    const icureResourceType: IcureResourceType = this.getIcureResourceType(resource);

    // Target tags (by reference): either <CalendarItem> tags or <Contact> -> (matching) <Service> -> tags
    const resourceTags: CodeStub[] = icureResourceType === IcureResourceType.CALENDARITEM
        ? (resource.tags || ((resource.tags = []) && resource.tags))
        : (((resource as Contact).services || (((resource as Contact).services = []) && (resource as Contact).services!)).find(svc => svc.id === calendarItemIdOrServiceId)?.tags || []);

    // Tag already there / resource already seen? Take no action
    if (resourceTags.some(tag => tag.type === VIEWED_ON_TAG_TYPE)) return false;

    // Do add "viewed on" tag / tstamp (by reference)
    resourceTags.push({
      version: '1',
      type: VIEWED_ON_TAG_TYPE,
      code: `${+new Date()}`
    });

    return true;
  }

  getIcureResourceType(resource: CalendarItem | Contact): IcureResourceType {
    return resource instanceof CalendarItem ? IcureResourceType.CALENDARITEM : IcureResourceType.CONTACT;
  }

  async getContactByServiceId(serviceId: string): Promise<Contact | undefined> {
    if (!serviceId) return;

    const service: Service | undefined = await this._api.contactApi.getService(serviceId).catch(e => undefined);
    return !service?.contactId ? undefined : this._api.contactApi.getContactWithUser(this._user, service.contactId).catch(e => undefined);
  }

  async getResourceByIdAndType(
    calendarItemIdOrServiceId: string,
    resourceType: ResourceType
  ): Promise<CalendarItem | Contact | undefined> {
    // Appointment could either be a <CalendarItem> or a <Service> (procedure); any other resourceType is a <Service>
    return resourceType === ResourceType.APPOINTMENTS
      ? this._api.calendarItemApi?.getCalendarItemWithUser(this._user, calendarItemIdOrServiceId).catch(e => this.getContactByServiceId(calendarItemIdOrServiceId))
      : this.getContactByServiceId(calendarItemIdOrServiceId);
  }

  private async _getServicesMatchingTags(
    tags: Code[],
    useComplement?: boolean
  ) {
    try {
      const list = await this._api?.contactApi.filterServicesBy(
        undefined,
        undefined,
        new FilterChainService({
          filter: useComplement
            ? new ComplementFilter(
                new ServiceByHcPartyTagCodeDateFilter({
                  healthcarePartyId: this._icurePatient.id,
                  tagCode: tags[0].code,
                  tagType: tags[0].type,
                }),
                new ServiceByHcPartyTagCodeDateFilter({
                  healthcarePartyId: this._icurePatient.id,
                  tagCode: tags[1].code,
                  tagType: tags[1].type,
                })
              )
            : new IntersectionFilter(
                tags.map(
                  (tag) =>
                    new ServiceByHcPartyTagCodeDateFilter({
                      healthcarePartyId: this._icurePatient.id!,
                      tagCode: tag.code,
                      tagType: tag.type,
                    })
                )
              ),
        })
      );

      const services = await this._api?.contactApi.decryptServices(
        this._icurePatient.id!,
        list?.rows!
      );
      // console.log(services)
      return services;
    } catch (e) {
      throw e;
    }
  }

  private _buildCalendarItem(
    agendaId: string,
    patient: Patient,
    hcp: string,
    { calendarItemTypeId, name, duration, address }: AppointmentTypeAndPlace,
    timeslot: number
  ): CalendarItem {
    const details = name;
    const phoneNumber = patient.addresses?.[0].telecoms?.find(
      (t) => t.telecomType === "mobile" || t.telecomType === "phone"
    )?.telecomNumber;
    return {
      agendaId,
      calendarItemTypeId,
      duration,
      address,
      title:`${patient.lastName?.toUpperCase()} ${patient.firstName} - ${phoneNumber} (via E-ZO)`,
      homeVisit: false,
      phoneNumber,
      details,
      responsible: hcp,
      startTime: Number(timeslot),
      endTime: Number(
        dayjs(String(timeslot), API_TIME_FORMAT)
          .add(duration!, "minutes")
          .format(API_TIME_FORMAT)
      ),
    };
  }

  private async _getDocumentsForTopic(
    topic: CommunicationTopic
  ): Promise<DiagnosticReportModel[]> {
    try {
      const contacts = await this._api?.contactApi.filterByWithUser(
        this._user,
        undefined,
        undefined,
        new FilterChainContact({
          filter: new UnionFilter(
            getTagsForTopic(topic).map(
              (tag) =>
                new ContactByHcPartyTagCodeDateFilter({
                  healthcarePartyId: this._icurePatient.id,
                  tagCode: tag.code,
                  tagType: tag.type,
                })
            )
          ),
        })
      );

      const contactsGroupedByGroupId = Object.entries(groupBy(contacts?.rows! as [], "groupId"));


      const reports = [];
      for (const groupedContacts of contactsGroupedByGroupId) {
        const ctcs = groupedContacts[1] as Contact[];
        const mostRecentContact = ctcs.sort((a, b) =>
          a.modified! > b.modified! ? -1 : 1
        )[0];
        //const encryptedServices = mostRecentContact.services!;
        let services:Service[] = (ctcs as Contact[]).flatMap(
          (ctc) => ctc.services
        ).map((s) => {
          return {
            ...s,
            modified: mostRecentContact.modified
          };
        }).filter(
          (value, index, self) =>
            self.findIndex((v) => v.id === value.id) === index
        );
       

        const hcp = await this.provider(services[0].responsible!);
        const lab = await this.provider((ctcs as Contact[])[0].responsible!);

        const formId =
          ((ctcs as Contact[])[0].subContacts as SubContact[])[0].formId ??
          (ctcs as Contact[])[0].id!;

        reports.push(
          topic === CommunicationTopic.REPORT_LABS
            ? icureLabResultsToReportModel(
                formId,
                this.patient,
                services,
                ctcs as Contact[],
                hcp,
                lab
              )
            : icureReportToDiagnosticReport(
                formId,
                this.patient,
                services,
                hcp,
                lab
              )
        );
      }

      return reports;
    } catch (e) {
      console.warn(e);
      return [];
    }
  }

  async sendEmail(
    to: string,
    subject: string,
    body: string
  ): Promise<any> {
    return await retry(
        async () => {
          const response: Response = await fetch(`${MESSAGE_HOST}/ms/email/to/${to!}`, {
            method: 'POST',
            headers: {
              'Content-Type': 'application/json',
              'Authorization': await this.getAuthorizationBearer(),
            },
            body: JSON.stringify({
              subject: subject,
              from: FROM_EMAIL,
              html: body,
            }),
          });
          if (!response.ok) {
            throw new Error(response.statusText);
          } else {
            // The 'send later" endpoint will return a JSON object with details
            // But the "send now" endpoint returns a simple "ok"
            // Therefore we need to check the content type and return the response accordingly
            var contentType = response.headers.get('content-type');
            if (contentType && contentType.indexOf('application/json') !== -1) {
              return await response.json();
            }
            return await response.text();
          }
        },
        5,
        500,
    );
  }

  public getIcurePatient(): Patient {
    return this._icurePatient;
  }
}

Object.defineProperty(MedispringConnector, "name", {
  value: "MedispringConnector",
});
