import Avatar from "../../ui/components/Avatar/Avatar";
import AvatarGroup from "../../ui/components/Avatar/AvatarGroup";
import {
  AgendaItemModel,
  AgendaItemStatusEnum,
} from "../../models/AgendaItemModel";
import { isHCPModel } from "../../models/HCPModel";
import {
  formatDateAndTime,
  formatName,
} from "../../utils/formatting";
import Icon from "../../ui/components/Icon/Icon";
import { Icons } from "../../ui/assets";
import { Color, Tints } from "../../ui/tokens";

export const AgendaItemRenderer = {
  iconRight: (item: AgendaItemModel) => (
    <AvatarGroup persons={item.participants} />
  ),
  icon: (item: AgendaItemModel) => (
    <Avatar
      square
      icon={
        <Icon
          asset={
            item.type === "FORM"
              ? Icons.icons.form
              : item.status === AgendaItemStatusEnum.PROPOSED
              ? Icons.specialties.heart
              : Icons.icons.clock_xs
          }
          color={Color.WHITE}
        />
      }
      tint={
        item.type === "FORM"
          ? Tints.FORM
          : item.status === AgendaItemStatusEnum.PROPOSED
          ? Tints.PREVENTION
          : Tints.APPOINTMENT
      }
    />
  ),

  title: (item: AgendaItemModel) => formatDateAndTime(item.start),
  subtitle: (item: AgendaItemModel) =>
    formatName(item.participants?.find((p) => isHCPModel(p))!),
  details: (item: AgendaItemModel) => item.description ?? "",
};
