import { useParams } from "react-router-dom";
import Button from "../../ui/components/Button";
import Typography from "../../ui/components/Typography/Typography";
import { useTranslation } from "react-i18next";

import {
  MedicationModel,
  RegimenModel,
} from "../../models/MedicationModel";
import useResource from "../../hooks/useResource";
import RegimenByPrescriber from "./RegimenByPrescriber";
import getMedicationShortName from "../../hooks/useMedicationShortName";
import Section from "../../ui/components/Layout/Section";
import ButtonGroup from "../../ui/components/Layout/ButtonGroup";
import { PrescriptionModel, PrescriptionRequestStatus } from "../../models/PrescriptionModel";
import Block, { BlockType } from "../../ui/components/Layout/Block";
import useConfirmation from "../../hooks/useConfirmation";
import { useAskForRefillMutation } from "../../connectors/medispring/api/medispring";
import Spacing from "../../ui/components/Layout/Spacing";
import { formatDate, formatName } from "../../utils/formatting";
import { ResourceType } from "../../types/ResourceType";
import Comment from "../../ui/components/Comment";
import { HCPModel } from "../../models/HCPModel";
import { Fragments } from "../home/HomeSection";

const MedicationDetailsScreen = () => {

  const { section, resourceId } = useParams();
  
  const { data: medication } = useResource<MedicationModel>(
    ResourceType.MEDICATIONS,
    resourceId,
    section !== Fragments.MEDICATION
  );
  const { data: prescription } = useResource<PrescriptionModel>(
    ResourceType.PRESCRIPTIONS,
    medication?.id,
    section !== Fragments.MEDICATION
  );

  const { data: prescriber } = useResource<HCPModel>(
    ResourceType.PROVIDERS,
    medication?.hcp as string,
    section !== Fragments.MEDICATION
  );

  /* useEffect(() => {
      if (document?.attachment) {
        const b = fetch(document?.attachment as string)
          .then(async response => await response.blob())
          .then(blob => {
            const reader = new FileReader();
            if (blob.type.includes('text')) {
              reader.onload = () => {
                console.log(reader.result as string);
              };
              reader.readAsText(blob);
            } else {
              reader.onload = () => {
                //setImg(reader.result as string);
              };
              reader.readAsDataURL(blob);
            }
            // setImg(getImageURI(response.blob()))
          });
      }
    }, [document]);
*/
  const { t } = useTranslation();
  const [askForRefill] = useAskForRefillMutation();
  const orderRefill = useConfirmation({
    alertTitle: "Demander un renouvellement?",
    alertMessage: "Voulez-vous demander un renouvellement de cette ordonnance?",
    onConfirm: () =>
      askForRefill({
        medications: [medication],
        hcp: medication?.regimen[0].prescriber!,
      }),
    confirmationMessage: "Votre demande de renouvellement a été envoyée",
    confirmationTitle: "Demande envoyée",
  });

  function groupByHcp(regimen: RegimenModel[] | undefined) {
    if (!regimen) return {};
    return regimen.reduce((result: { [id: string]: RegimenModel[] }, item) => {
      if (!result[item.prescriber!]) result[item.prescriber!] = [];
      result[item.prescriber!].push(item);
      return result;
    }, {});
  }

 

  const regimen = Object.entries(groupByHcp(medication?.regimen));


  return (
    <>
      <Typography.h1>{getMedicationShortName(medication?.name)}</Typography.h1>
      <Spacing large />

      {!!medication?.regimen?.length && (
        <Section
          title="Posologie(s)"
          info={`${t("treatment.prescribed_by")} ${formatName(
            prescriber
          )}, ${formatDate(medication.updated)}`}
        >
          {medication?.regimen?.length ? (
            regimen.map(([hcp, list]) => (
              <RegimenByPrescriber
                key={hcp}
                hcpId={hcp}
                regimen={list}
                medication={medication}
              />
            ))
          ) : (
            <Typography.body>{medication?.posology}</Typography.body>
          )}
        </Section>
      )}

      {medication?.commentForDelivery && (
        <Comment
          text={medication?.commentForDelivery}
          author={prescriber}
          date={formatDate(medication.updated)}
        ></Comment>
      )}

      {/*      <Block>
        <div style={{display:"flex", width:"100%", justifyContent:"space-between", alignItems:"center"}}>
        <Typography.body>Me rappeler de prendre ce médicament</Typography.body>
        <Toggle/>
        </div>
        
      </Block>
 */}

      {/*prescription?.rId && (
        <Section title="Prescription(s)">
          <Attachment prescription={prescription} />
        </Section>
      )*/}

      {prescription &&
      
      medication?.isChronic &&
        (prescription?.status === PrescriptionRequestStatus.DRAFT ? (
          <Block type={BlockType.SUCCESS}>
            <Typography.body>
              {t("treatment.refill_request_received") ?? ""}
            </Typography.body>
          </Block>
        ) : (
          <ButtonGroup>
            <Spacing large />
            <Button
              primary
              title={t("treatment.refill") as string}
              onPress={orderRefill}
            />
          </ButtonGroup>
        ))}
    </>
  );
};

export default MedicationDetailsScreen;
