import { Color, Size } from "../tokens";

interface Props {
  label?: string;
  property?: string;
  checked?: boolean;
  onChange?: () => void;
}

const Checkbox = ({ label, property, checked, onChange = () => {} }: Props) => {
  return (
    <div
      style={{
        display: "flex",
        gap: 8,
        justifyItems: "center",
        marginTop: Size.S,
      }}
    >
      <input
        style={{
          flexGrow: 0,
          width: 16,
          accentColor: Color.NEUTRAL_800,
          borderRadius: Size.XS,
        }}
        type="checkbox"
        autoComplete={property}
        checked={checked}
        onChange={onChange}
      />
      <label style={{ flex: 1 }}>{label}</label>
    </div>
  );
};

export default Checkbox;
