import EzoList, {
  EzoListRenderingProps,
} from "../../../../ui/components/List/EzoList";
import useResources from "../../../../hooks/useResources";
import { HCPModel } from "../../../../models/HCPModel";
import { ResourceType } from "../../../../types/ResourceType";
import Avatar from "../../../../ui/components/Avatar/Avatar";
import useUpdateSearchParams from "../../../../hooks/useUpdateSearchParams";
import { getLocalizedSpecialty } from "../../../../utils/formatting";

const HCPSelectionScreen = () => {
  const { data } = useResources<HCPModel>(ResourceType.PROVIDERS, true);

  const updateParam = useUpdateSearchParams();

  return (
    <div
      style={{
        display: "flex",
        flexDirection: "column",
        justifyContent: "space-between",
        height: "100%",
      }}
    >
      <EzoList
        data={data}
        icon={(hcp) => <Avatar person={hcp} large />}
        title={(hcp) => `${hcp.firstName} ${hcp.lastName}`}
        details={(hcp) => getLocalizedSpecialty(hcp.specialty ?? "")}
        onItemSelection={(hcp) => updateParam("hcp", hcp.id)}
      />

      {/*
      <ButtonGroup>
     
      <Button title="Trouver un professionel de santé" />
      
      </ButtonGroup>
        */}
    </div>
  );
};

export const providersRenderProps: EzoListRenderingProps<HCPModel> = {
  icon: (item: HCPModel) => <Avatar person={item} large />,
  title: (item: HCPModel) => `${item.firstName} ${item.lastName}`,
  subtitle: (item: HCPModel) => item.specialty ?? "",
};

export default HCPSelectionScreen;
