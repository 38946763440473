import React from "react";
import styles from "./Typography.styles";

interface Props {
  children: JSX.Element | string | string[] | (JSX.Element | string | string[] | undefined)[] | undefined;
  color?: string;
  align?: "left" | "center" | "right";
  style?: React.CSSProperties;
}

type TypeStyle = keyof typeof styles;
type TypeSet = Record<TypeStyle, React.FC<Props>>;

const Typography: Partial<TypeSet> = {};

Object.entries(styles).forEach(([key, s]) => {
  Typography[key as TypeStyle] = React.memo(({ children, color, align, style }: Props) => (
    <span style={{ ...s, ...style, color, textAlign: align ?? "left" }} className={key}>
      {children}
    </span>
  ));
});

export default Typography as NonNullable<TypeSet & Props>;
