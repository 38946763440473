import Typography from "../../ui/components/Typography/Typography";
import Section from "../../ui/components/Layout/Section";
import EzoList from "../../ui/components/List/EzoList";
import EmptyState from "../../ui/components/EmptyState";

import Assets, {Icons} from "../../ui/assets";

import {
  AgendaItemModel,
  AgendaItemStatusEnum,
  antiChronologically,
  chronologically,
  isNotPast,
  isPast,
} from "../../models/AgendaItemModel";

import {useNavigate} from "react-router-dom";
import useResources from "../../hooks/useResources";
import SectionShimmer from "../../ui/components/Loading/SectionShimmer";

import {AgendaItemRenderer} from "./AgendaItemRenderer";
import useDisplayOption from "../../hooks/useDisplayOption";
import {useTranslation} from "react-i18next";
import Avatar from "../../ui/components/Avatar/Avatar";
import {Color, Tints} from "../../ui/tokens";
import Icon from "../../ui/components/Icon/Icon";
import {ResourceType} from "../../types/ResourceType";
import useIsCurrentRoute from "../../hooks/useIsCurrentRoute";
import {isHCPModel} from "../../models/HCPModel";
import {Fragments} from "../home/HomeSection";
import {useFlowContext} from "../../ui/components/ScreenFlow/FlowContext";
import FilterButton from "../../ui/components/FilterButton";
import {FlowScreenProps} from "../../ui/components/ScreenFlow/ScreenFlow";
import {useMarkResourceAsViewedMutation} from "../../api/healthdata";

const AppointmentsListScreen = ({name}:FlowScreenProps) => {
  const [showBooked] = useDisplayOption("agenda.booked");
  const [showScheduled] = useDisplayOption("agenda.scheduled");
  const [showPast] = useDisplayOption("agenda.past");
  //const visiblePatients = useSelector(selectVisiblePatients)

  const isScreenVisible = useIsCurrentRoute("/agenda");

  const {setActions} = useFlowContext();

  setActions(
    name,
    <FilterButton/>);

  //const appointments = mock;//.filter(a=>!a.participants || a.participants?.some(p=>visiblePatients[p.id]));
  const {
    data: appointments,
    isLoading
  } = useResources<AgendaItemModel>(ResourceType.APPOINTMENTS, isScreenVisible);
  const navigate = useNavigate();

  const scheduled = showBooked.value
    ? appointments
        ?.filter(
          (a) =>
            a.status === AgendaItemStatusEnum.BOOKED ||
            a.status === AgendaItemStatusEnum.PENDING
        )
        .filter(isNotPast)
        .sort(antiChronologically)
    : [];

  const proposed: AgendaItemModel[] = showScheduled.value
    ? appointments
        ?.filter((a) => a.status === AgendaItemStatusEnum.PROPOSED)
        .filter(isNotPast)
        .sort(chronologically)
    : [];

  const past: AgendaItemModel[] = showPast.value
    ? appointments?.filter(isPast).sort(chronologically)
    : [];

  const { t } = useTranslation();
 
  const [markResourceAsViewed] = useMarkResourceAsViewedMutation();

  return (
    <>
      <Typography.h1>{t("menu.agenda") ?? "Agenda"}</Typography.h1>
      {isLoading ? (
        <SectionShimmer />
      ) : appointments?.length === 0 ||
        proposed?.length + scheduled?.length + past?.length === 0 ? (
        <EmptyState
          title="No appointments to display"
          image={Assets.empty!.agenda}
        />
      ) : (
        <>
          {showBooked.value && scheduled?.length > 0 && (
            <Section title={t("agenda.scheduled") as string}>
              <EzoList
                data={scheduled}
                {...AgendaItemRenderer}
                onItemSelection={(item) => {
                  markResourceAsViewed({resourceId: item.id, resourceType: ResourceType.APPOINTMENTS});
                  navigate(`./${item.id}`) }
                }
              />
            </Section>
          )}

          {showScheduled.value && proposed?.length > 0 && (
            <Section title={t("agenda.proposed") as string}>
              <EzoList
                data={proposed}
                {...AgendaItemRenderer}
                subtitle={(item) => item.title!}
                iconRight={(item) => (
                  <Avatar
                    small
                    person={item.participants?.find((p) => !isHCPModel(p))}
                  />
                )}
                onItemSelection={(item) => {
                  markResourceAsViewed({resourceId: item.id, resourceType: ResourceType.APPOINTMENTS});
                  navigate(`../${Fragments.AGENDA}/${item.id}`)
                }}
                icon={(item) => (
                  <Avatar
                    square
                    tint={Tints.PREVENTION}
                    icon={
                      <Icon
                        asset={Icons.specialties.heart}
                        color={Color.WHITE}
                      />
                    }
                  />
                )}
              />
            </Section>
          )}

          {showPast.value && past?.length > 0 && (
            <Section title="Rendez-vous passés">
              <EzoList
                data={past}
                {...AgendaItemRenderer}
                onItemSelection={(item) => navigate(`./${item.id}`)}
              />
            </Section>
          )}
        </>
      )}
    </>
  );
};

export default AppointmentsListScreen;
