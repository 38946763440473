import { CSSProperties } from "react";
import { Color} from "../../tokens";

const defaultStyle: CSSProperties = {
  color: Color.NEUTRAL_800,
  fontFamily: "Poppins, sans-serif",
  fontSize: "16px",
  lineHeight: "22px",
  fontSmooth: "always",
  WebkitFontSmoothing: "subpixel-antialiased", // -webkit-font-smoothing: antialiased applied for better legibility
  textRendering: "optimizeLegibility",
  fontWeight: 400,
  wordBreak: "break-word",
  display: "block",
};

export default {
  body: {
    ...defaultStyle,
  },
  strong: {
    ...defaultStyle,
    fontWeight: 500,
  },
  link: {
    color: Color.ACCENT,
    fontSize: 16,
    lineHeight: 22,
  },
  h1: {
    ...defaultStyle,

    /*
    ...defaultStyle,
    fontSize: 34,
    lineHeight: "41px",
    fontWeight: 600,
    display: "block",
    width: "80%",*/
    fontStyle: "normal",
    fontWeight: 600,
    fontSize: "34px",
    lineHeight: "41px",
    letterSpacing: "-1px",
    width: "80%",
  },
  h2: {
    ...defaultStyle,
    color: Color.ACCENT,
    /*
    ...defaultStyle,
    color: Color.ACCENT,
    fontSize: "20px",
    lineHeight: "29px",
    fontWeight: 600,
    display: "block",*/
    letterSpacing: "-.5px",
    fontWeight: 600,
    fontSize: "21px",
    lineHeight: "28px",
    /* identical to box height, or 127% */
  },
  h3: {
    ...defaultStyle,
    fontSize: "17px",
    lineHeight: "24px",
    display: "block",
    fontWeight: 600,
    lineClamp: 2,
  },
  h4: {
    ...defaultStyle,
    color: Color.ACCENT,
    fontSize: "12px",
    lineHeight: "28px",
    textTransform: "uppercase",
    fontWeight: 700,
  } as CSSProperties,

  small: {
    ...defaultStyle,
    fontSize: "13px",
    lineHeight: "18px",
    opacity: 0.75,
  } as CSSProperties,
  xsmall: {
    ...defaultStyle,
    color: Color.NEUTRAL_600,
    fontSize: "11px",
    lineHeight: "10px",
  },
};
