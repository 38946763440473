import {useEffect, useRef, useState} from "react";
import Button from "../../ui/components/Button";
import Typography from "../../ui/components/Typography/Typography";

import {useDispatch, useSelector} from "react-redux";
import {enterTheApp, selectAllPatients, selectConnections,} from "./AuthSlice";
import {ConnectionStatus, ConnectorOptions,} from "../../connectors/ConnectorProvider";
import {DotLottiePlayer} from "@dotlottie/react-player";
import {useConnectMutation} from "../../api/authentication";
import EzoList from "../../ui/components/List/EzoList";
import Section from "../../ui/components/Layout/Section";
import {Spinner} from "../../ui/components/Loading/Spinner";
import Success from "../../ui/components/Loading/Success";
import Spacing from "../../ui/components/Layout/Spacing";
import Warning from "../../ui/components/Loading/Warning";
import {useTranslation} from "react-i18next";
import {useModalSheet} from "../../ui/components/ModalSheet/useModalSheet";
import DownloadKeyScreen from "../../connectors/medispring/features/auth/DownloadKeyScreen";

const ConnectionScreen = () => {
  const dispatch = useDispatch();

  const connections = useSelector(selectConnections) as ConnectorOptions[];

  const [currentConnection, setCurrentConnection] =
    useState<ConnectorOptions>();

    const [connect] = useConnectMutation();

  const [canConnect, setCanConnect] = useState(false);
  const fileInput = useRef<HTMLInputElement>(null);

  const showModal = useModalSheet();


  const [isConnecting, setIsConnecting] = useState(true);

  const downloadKey = (key:string,name:string) => {
      const blob = new Blob([key], { type: "text/plain;charset=utf-8" });
      const href = URL.createObjectURL(blob);
      const link = document.createElement("a");
      link.href = href;
      link.download = name;
      document.body.appendChild(link);
      link.click();
      document.body.removeChild(link);
    
  };

  useEffect(() => {
    const activeConnections = connections.filter(
      (connection) => connection.status === ConnectionStatus.CONNECTED
    );
    if (
      connections.length > 0 &&
      connections.length - activeConnections.length === 0 &&
      isConnecting
    ) {
      setTimeout(() => {
        setIsConnecting(false);
        const c = activeConnections.find(c => c.recoveryKey && c.pki)
        if (c){
          showModal(<DownloadKeyScreen/>)
        downloadKey(c?.pki!,"cle_bianca.txt")
         //console.log("Used recovery keys:",activeConnections.filter(c => c.recoveryKey))
        }
        // dispatch(enterTheApp());
      }, 1000);
    } else{
      //setIsConnecting(false);
      setCanConnect(activeConnections.length > 0);
    }
  }, [connections, dispatch, isConnecting, showModal]);

  const handleFileRead = (e: ProgressEvent<FileReader>) => {
    const content = e.target?.result;
    if (content && typeof content === "string")
      
       connect({
          ...currentConnection!.credentials,
          pki: content as string,
        })
      

    //importPKI({...credentials!, pki:content as string});
  };

  const handleFileChosen = (file?: Blob) => {
    if (!file) return;
    let fileReader = new FileReader();
    fileReader.onloadend = handleFileRead;
    fileReader.readAsText(file);
  };

  const patient = useSelector(selectAllPatients)?.[0];
  const { t } = useTranslation();

  return (
    <div style={{display:"flex",flexDirection:"column", alignItems:"center"}}>
     <DotLottiePlayer   
        src="https://lottie.host/ebc3db0f-b469-4fa4-a896-6c782e0852d5/lcr5XT5jsg.json"
        background="transparent"
        speed={1}
        style={{ width: 160, height: 160 }}
        direction={1}
        loop={isConnecting}
        autoplay
      />
      <Spacing large/>
      <Typography.h3 align="center">Bienvenue {patient?.firstName ?? ""}!</Typography.h3>
      <Typography.body align="center">Un instant, nous récupérons vos données...</Typography.body>

     

      <input
        type="file"
        id="file"
        accept=".txt"
        style={{ display: "none" }}
        onChange={(e) => {
          if (e.target?.files?.length) handleFileChosen(e.target?.files[0]);
        }}
        ref={fileInput}
      />

<div style={{width:"100%"}}>
<Section>
      <EzoList
      noChevron
        data={connections}
        title={connection=> (connection?.id ?? '').substring(0, 25) + "..."}
        details={connection=>t(`login.${connection.status}`) ?? "..."}
        icon={connection=> connection.status === ConnectionStatus.ERROR ?  <Warning/> : connection.status === ConnectionStatus.CONNECTED ? <div style={{transform:"scale(.6)"}}><Success/></div> : <Spinner/> }
        onItemSelection={connection => {
          setCurrentConnection(connection);
          fileInput.current?.click();
        }}/>
</Section>
{canConnect && (
          <Button
            primary
            title="Proceed"
            onPress={() => dispatch(enterTheApp())}
          />
      )}
</div>


     
    </div>
  );
};

export default ConnectionScreen;
