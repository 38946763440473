import { useTranslation } from "react-i18next";
import useResources from "../../../hooks/useResources";
import { HCPModel } from "../../../models/HCPModel";
import Typography from "../../../ui/components/Typography/Typography";
import EmptyState from "../../../ui/components/EmptyState";
import Assets, { Icons } from "../../../ui/assets";
import SectionShimmer from "../../../ui/components/Loading/SectionShimmer";
import { ResourceType } from "../../../types/ResourceType";
import useIsCurrentRoute from "../../../hooks/useIsCurrentRoute";
import EzoList from "../../../ui/components/List/EzoList";
import styled from "styled-components";
import { Color, Size } from "../../../ui/tokens";
import Button from "../../../ui/components/Button";
import Icon from "../../../ui/components/Icon/Icon";
import { ContactPointUse } from "../../../models/ContactPoint";
import Section from "../../../ui/components/Layout/Section";
import { useModalSheet } from "../../../ui/components/ModalSheet/useModalSheet";
import CreateAppointmentFlow from "../../../connectors/medispring/features/agenda/CreateAppointmentFlow";
import useUpdateSearchParams from "../../../hooks/useUpdateSearchParams";
import Avatar from "../../../ui/components/Avatar/Avatar";
import { formatName, getLocalizedSpecialty } from "../../../utils/formatting";
import { FlowScreenProps } from "../../../ui/components/ScreenFlow/ScreenFlow";

const ButtonContainer = styled.div`
  display: flex;
  gap: ${Size.XS}px!important;
`;

const ProvidersListScreen = ({name}:FlowScreenProps) => {
  const isScreenVisible = useIsCurrentRoute("/providers");

  const { data, isLoading } = useResources<HCPModel>(
    ResourceType.PROVIDERS,
    isScreenVisible
  );

  const updateParam = useUpdateSearchParams();

  const setModal = useModalSheet();
  const { t } = useTranslation();

  return (
    <>
      <Typography.h1>{t("menu.team") ?? ""}</Typography.h1>

      {data?.length === 0 ? (
        <EmptyState title="Nothing to see here" image={Assets.empty.messages} />
      ) : isLoading ? (
        <SectionShimmer />
      ) : (
        <>
          <Section>
            <EzoList
              data={data}
              title={(item: HCPModel) => formatName(item)}
              details={(item: HCPModel) =>
                item.specialty ? getLocalizedSpecialty(item.specialty) : ""
              }
              icon={(item: HCPModel) => <Avatar person={item} />}
              iconRight={(item: HCPModel) => (
                <ButtonContainer>

                  {item?.telecom?.some(
                    (telecom) => telecom.use === ContactPointUse.MOBILE
                  ) && (
                    <a
                      href={`tel:${
                        item?.telecom?.find(
                          (telecom) => telecom.use === ContactPointUse.MOBILE
                        )?.value
                      }`}
                    >
                      <Button
                        circle
                        small
                        icon={
                          <Icon
                            asset={Icons.icons.landline_fill}
                            color={Color.NEUTRAL_600}
                          />
                        }
                      />
                    </a>
                  )}
                  <Button
                    small
                    circle
                    onPress={() => {
                      setModal(<CreateAppointmentFlow key="create-appointment" />)
                      updateParam("hcp", item.id);
                    }}
                    icon={
                      <Icon
                        asset={Icons.icons.calendar_fill}
                        color={Color.NEUTRAL_600}
                      />
                    }
                  />
                </ButtonContainer>
              )}
            />
          </Section>
        </>
      )}
    </>
  );
};

export default ProvidersListScreen;
