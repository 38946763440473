import React, { useEffect, useState } from "react";
import styled from "styled-components";
import { Size, Color, Breakpoint } from "../../ui/tokens";

import { NavLink, useLocation } from "react-router-dom";
import Assets, { Icons } from "../../ui/assets";
import { logout, selectAllPatients } from "../login/AuthSlice";
import { useDispatch, useSelector } from "react-redux";
import Icon from "../../ui/components/Icon/Icon";
import Alert from "../../ui/components/Alert";
import { useIdleTimer } from "react-idle-timer";



const NavigationContainer = styled.div`
  display: flex;
  flex-direction: column;
  gap: 24px;
  padding: ${Size.M}px;
  height: calc(100% - ${Size.XXL}px);
  justify-content: space-between;
  align-items: flex-start;

  @media (max-width: ${Breakpoint.MOBILE}px) {
    position: absolute;
    width: 275px;
  }

  @media (min-width: ${Breakpoint.DESKTOP}px) {
    width: 275px;
  }
  @media (min-width: ${Breakpoint.MOBILE}px) and (max-width: ${Breakpoint.DESKTOP}px) {
    width: 64px;
  }
`;

const MenuContainer = styled.div`
  display: flex;
  flex-direction: column;
  gap: 24px;
`;

const NavItem = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  padding: 4px 8px;
  gap: ${Size.M}px;
  font-size: 20px;
  line-height: 24px;
  text-align: left;
  background: none;
  font-family: "Poppins", sans-serif;

  > img {
    margin-right: 8px;
  }

  > span {
    @media (max-width: ${Breakpoint.DESKTOP}px) {
      display: none;
    }
  }
`;

const LogoContainer = styled.div`
  transform: translateX(-${Size.S}px);
  
  @media (max-width: ${Breakpoint.DESKTOP}px) {
    transform: scale(0.5) translateX(-100px);
    
  }
  
`;

const TIME_OUT = 5 * 60 * 1000;
const PROMPT_BEFORE_IDLE = 15 * 1000;

const Navigation = () => {
  const location = useLocation();
  const path = location.pathname;

  const [remaining, setRemaining] = useState(TIME_OUT);

  const patients = useSelector(selectAllPatients);

  const dispatch = useDispatch();

  const onIdle = () => {
    if (patients) {
      Alert.close();
      dispatch(logout());
    }
  };

  const onPrompt = () => {
    if (patients) {
      Alert.show(
        "Etes vous encore là?",
        "Pour des raisons de sécurité, en l'absence d'action de votre part vous serez automatiquement déconnecté(e) dans " +
          Math.round(PROMPT_BEFORE_IDLE / 1000) +
          " secondes.",
        [
          {
            label: "Poursuivre ma session",
            type: "primary",
            action: () => {
              activate();
              Alert.close();
            },
          },
        ]
      );
    }
  };

  useEffect(() => {
    const interval = setInterval(() => {
      setRemaining(Math.ceil(getRemainingTime() / 1000));
    }, 500);

    return () => {
      clearInterval(interval);
    };
  });

  const { getRemainingTime, activate } = useIdleTimer({
    onIdle,
    onPrompt,
    timeout: TIME_OUT,
    promptBeforeIdle: PROMPT_BEFORE_IDLE,
  });

  const closeSidebar = () => {
    document.querySelector(".content")?.classList.remove("sidebar-active");
  };

  const navItems = [
    {
      icon: Icons.navigation.home,
      iconSelected: Icons.navigation.home_selected,
      label: "Accueil",
      key: "",
    },
    {
      icon: Icons.navigation.agenda,
      iconSelected: Icons.navigation.agenda_selected,
      label: "Agenda",
      key: "agenda",
    },
    {
      icon: Icons.navigation.treatment,
      iconSelected: Icons.navigation.treatment_selected,
      label: "Médicaments",
      key: "medication",
    },

    {
      icon: Icons.navigation.docs,
      iconSelected: Icons.navigation.docs_selected,
      label: "Documents",
      key: "documents",
    },
    {
      icon: Icons.navigation.team,
      iconSelected: Icons.navigation.team_selected,
      label: "Soignants",
      key: "providers",
    },
    /*{
      icon: Icons.navigation.vaccination,
      iconSelected: Icons.navigation.vaccination_selected,
      label: "Vaccins",
      key: "vaccination",
    },
    */
  ];

  function getIconForPath(item: any, path: string) {
    const DefaultIcon = item.icon;
    const SelectedIcon = <Icon asset={item.iconSelected} />;
    const section = path.split("app/").pop();

    return section?.includes(item.key) && item.key !== "" || (item.key === "" && section === "") ? (
      <Icon asset={item.iconSelected} color={Color.NEUTRAL_800} />
    ) : (
      <DefaultIcon />
    );
  }

  return (
    <NavigationContainer>
      <MenuContainer>
        <LogoContainer>
          <Icons.navigation.logo_small />
          {/*<UserProfile person={patients && patients[0]} />*/}
        </LogoContainer>

        {navItems.map((item, index) => (
          <NavLink
            to={`./${item.key}`}
            key={index}
            style={({ isActive }) => {
              const section = path.split("app/").pop();
              const isSelected = section?.includes(item.key) && item.key !== "" || (item.key === "" && section === "");
              return {
                fontWeight: isSelected ? 600 : 400,
                color: !isSelected ? Color.NEUTRAL_800 : Color.NEUTRAL_900,
              };
            }}
          >
            <NavItem key={index} onClick={() => closeSidebar()}>
              {getIconForPath(item, path)}
              <span>{item.label}</span>
            </NavItem>
          </NavLink>
        ))}
      </MenuContainer>
    </NavigationContainer>
  );
};

export default Navigation;
